import React from 'react';
import {Box, Typography} from '@mui/material';
import theme from 'theme';

interface EditModeBusinessEntitiesProps {
    activeStep: number;
}

const textEditMode = [
    'Entity details',
    'Entity DIM table',
    'Related datasets',
    'Related entities'
];

const EditModeBusinessEntities: React.FC<EditModeBusinessEntitiesProps> = ({activeStep}) => {

    return (
        <Box className="flex-box-center" sx={{margin: '20px -10px 0 -10px'}}>
            {textEditMode.map((text, index) => {
                const isActive = activeStep === index;
                return (
                    <Typography
                        key={index}
                        variant="subtitle2"
                        sx={{
                            color: isActive ? theme.palette.customColor.purple : theme.palette.customColor.slateGrey,
                            margin: '0 10px',
                            padding: '6px 10px',
                            borderRadius: isActive ? '10px' : 'none',
                            border: isActive ? `1px solid ${theme.palette.customColor.purple}` : 'none',
                            background: isActive ? `rgba(103, 73, 244, 0.10)` : 'none'
                        }}
                    >
                        {text}
                    </Typography>
                );
            })}
        </Box>
    );
};

export default EditModeBusinessEntities;
