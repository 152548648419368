import React, {useState, useEffect} from "react";
import {styled, Theme} from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Switch, {SwitchProps} from "@mui/material/Switch";
import {GitBranch} from "lucide-react";
import theme from "theme";
import {getGitBranches} from "services/entities";
import {useGlobalContext} from "context/global-context";
import Select, {SelectChangeEvent} from "@mui/material/Select";
import {Autocomplete, TextField} from "@mui/material";


const CustomSwitch = styled((props: SwitchProps) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({theme}: { theme: Theme }) => ({
    height: "auto",
    width: "auto",
    padding: "0",
    "& .MuiSwitch-switchBase": {
        padding: 0,
        margin: "5px 0 0 4px",
        transitionDuration: "300ms",
        "&.Mui-checked": {
            transform: "translateX(16px)",
            color: theme.palette.customColor.white,
            "& + .MuiSwitch-track": {
                border: `2px solid ${theme.palette.customColor.purple}`,
                backgroundColor: theme.palette.mode === "dark" ? theme.palette.customColor.lightGrey3 : theme.palette.customColor.purple,
                opacity: 1,
            },
            "&.Mui-disabled + .MuiSwitch-track": {
                opacity: 0.5,
            },
            "& .MuiSwitch-thumb": {
                width: "20px",
                height: "20px",
                backgroundColor: theme.palette.customColor.white,
                margin: "-2px 0 0 3px",
            },
        },
        "&.Mui-focusVisible .MuiSwitch-thumb": {
            color: theme.palette.customColor.brightGreen,
            border: "6px solid #fff",
        },
        "&.Mui-disabled .MuiSwitch-thumb": {
            color: theme.palette.customColor.purple,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
            opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
            cursor: "not-allowed"
        },
    },
    "& .MuiSwitch-thumb": {
        boxSizing: "border-box",
        width: 16,
        height: 16,
        backgroundColor: theme.palette.customColor.slateBlue,
    },
    "& .MuiSwitch-track": {
        width: 46,
        height: 26,
        borderRadius: "100px",
        border: "2px solid #DBDADD",
        boxSizing: "border-box",
        backgroundColor: theme.palette.mode === "light" ? theme.palette.customColor.lightestGrey3 : theme.palette.customColor.lightestGrey4,
        opacity: 1,
        transition: theme.transitions.create(["background-color"], {
            duration: 500,
        }),
    },
}));

const HeaderSwitch = () => {
    const {
        isEditMode,
        toggleEditMode,
        selectedGitBranch,
        setSelectedGitBranch
    } = useGlobalContext();
    const [branches, setBranches] = useState<string[]>([]);

    const handleChange = () => {
        toggleEditMode((isEditMode) => !isEditMode);
    };

    const handleBranchSelect = (event: any, branch: string | null) => {
        if (branch !== selectedGitBranch) setSelectedGitBranch(branch);
    };

    useEffect(() => {
        const getBranches = async () => {
            try {
                const data = await getGitBranches();
                setBranches(data);
                // initialize selected branch as the first one from the list
                if (!selectedGitBranch && data?.length > 0) setSelectedGitBranch(data[0]);
            } catch (error: any) {
                console.error(error);
            }
        };
        if (branches.length === 0) getBranches();
    }, [branches]);

    return (
        <Box className="flex-box-align-center" sx={{marginLeft: "40px"}}>
            <CustomSwitch checked={isEditMode}
                          onChange={handleChange}/>
            <Typography variant="h5" noWrap sx={{color: theme.palette.customColor.grey, ml: "16px"}}>
                Edit mode
            </Typography>
            {isEditMode && (
                <Autocomplete
                    options={branches}
                    disableCloseOnSelect
                    className="branch-select-component"
                    value={selectedGitBranch}
                    onChange={handleBranchSelect}
                    renderOption={(props, option) => {
                        const {key, ...optionProps} = props;
                        return (
                            <Box
                                key={key}
                                component="li"
                                {...optionProps}
                            >
                                <Box
                                    sx={{
                                        display: "flex",
                                        backgroundColor: theme.palette.customColor.lightestGrey2,
                                        borderRadius: "8px",
                                        marginRight: "10px",
                                        padding: "8px",
                                    }}
                                >
                                    <GitBranch size="18"/>
                                </Box>
                                <Typography variant="subtitle2">{option}</Typography>
                            </Box>
                        )
                    }}
                    renderInput={(params) => (
                        <Box sx={{display: "flex"}}>
                            <Box
                                sx={{
                                    display: "flex",
                                    backgroundColor: theme.palette.customColor.lightestGrey2,
                                    borderRadius: "8px",
                                    marginRight: "10px",
                                    padding: "8px",
                                    "& svg": {
                                        marginRight: 0,
                                    }
                                }}
                            >
                                <GitBranch size="18"/>
                            </Box>
                            <TextField
                                {...params}
                                className="branch-option flex-box-align-center"
                                fullWidth
                                variant="standard"
                            />
                        </Box>
                    )}
                />
            )}
        </Box>
    );
};

export default HeaderSwitch;
