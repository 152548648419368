import React, {useState, useEffect} from "react";
import {Box, Typography, IconButton, TextField} from "@mui/material";
import ButtonComponent from "../../../../../shared/button/button.component";
import AddFeaturesMeasuresModal from "./add-features-measures-modal/add-features-measures-modal";
import theme from "theme";
import "./add-features-measures.scss";
import {DataAssetFullResponse} from "services/data-assets";
import {MeasureType, MetricType} from "../../../../feature-types";

interface AddFeaturesMeasuresProps {
    selectedAsset: DataAssetFullResponse | null;
    measures?: MetricType["measures"];
    onMeasuresUpdated: (measured: MetricType["measures"]) => void;
}

const AddFeaturesMeasures: React.FC<AddFeaturesMeasuresProps> = ({
                                                                     selectedAsset,
                                                                     measures = [],
                                                                     onMeasuresUpdated
                                                                 }) => {
    const [open, setOpen] = useState(false);
    const [selectedMeasures, setSelectedMeasures] = useState<MeasureType[]>(measures);

    const handleOpenModalMeasures = () => {
        setOpen(true);
    };

    const handleCloseModalMeasures = () => {
        setOpen(false);
    };

    const handleRemoveItem = (measureName: string) => {
        if (measures?.length) {
            const updatedMeasures = measures.map(
                (m) => m.name === measureName ? {...m, selected: false} : m);
            onMeasuresUpdated(updatedMeasures);
            setSelectedMeasures(measures.filter(m => m.selected === true));
        }
        // onMeasuresUpdated(measures.filter((m) => m.name !== measureName));
    };

    const handleMeasuresSelected = (measures: MeasureType[]) => {
        setSelectedMeasures(measures.filter(m => m.selected === true));
        onMeasuresUpdated(measures);
    }

    const updateMeasureAlias = (measureName: MeasureType["name"], newAlias: string) => {
        const updatedSelectedMeasures = selectedMeasures.map(
            (m) => m.name === measureName ? {...m, alias: newAlias} : m);
        setSelectedMeasures(updatedSelectedMeasures);
        const updatedMeasures = measures.map(
            (m) => m.name === measureName ? {...m, alias: newAlias} : m);
        onMeasuresUpdated(updatedMeasures);
    }

    return (
        <Box className="flex-box-col-start">
            <Typography
                variant="subtitle2"
                sx={{color: theme.palette.customColor.dark, fontWeight: 600, margin: "10px 0 12px 0"}}
            >
                Measures
            </Typography>
            <Box sx={{width: "100%"}}>
                {selectedMeasures?.map((item) => (
                    <Box
                        key={item.name}
                        className="measures-selected"
                        sx={{
                            position: "relative",
                            marginBottom: "12px",
                            "&:last-child": {
                                marginBottom: 0,
                            }
                        }}
                    >
                        <Box className="flex-box-align-center" sx={{marginBottom: "6px"}}>
                            <span
                                className="material-symbols-outlined"
                                style={{
                                    fontSize: "24px",
                                    color: theme.palette.customColor.purple,
                                    marginRight: "6px",
                                }}
                            >
                                match_case
                            </span>
                            <Typography
                                variant="subtitle2"
                                sx={{color: theme.palette.customColor.dark}}
                            >
                                {item.name}
                            </Typography>
                        </Box>
                        <Box className="flex-box-align-center" sx={{marginLeft: "18px", padding: "6px"}}>
                            <span className="material-symbols-outlined" style={{fontSize: "16px"}}>
                                alternate_email
                            </span>
                            <TextField
                                className="alias-input"
                                placeholder="Enter alias.."
                                onChange={(e) => updateMeasureAlias(item.name, e.target.value)}
                                sx={{
                                    margin: "0 0 0 5px"
                                }}
                            />
                        </Box>
                        <IconButton
                            onClick={() => handleRemoveItem(item.name)}
                            sx={{
                                position: "absolute",
                                top: "4px",
                                right: "4px",
                                color: theme.palette.customColor.darkGrey,
                                "&:hover": {
                                    color: theme.palette.customColor.purple,
                                },
                            }}
                        >
                            <span className="material-symbols-outlined" style={{fontSize: "18px"}}>
                                delete
                            </span>
                        </IconButton>
                    </Box>
                ))}
            </Box>
            <ButtonComponent
                onClick={handleOpenModalMeasures}
                variant="textPurple"
                label="+ Add measures"
                sx={{fontSize: "16px", fontWeight: 600, marginTop: "12px", padding: 0}}
            />
            {Boolean(selectedAsset) && <AddFeaturesMeasuresModal
                open={open}
                onClose={handleCloseModalMeasures}
                selectedAsset={selectedAsset as DataAssetFullResponse}
                measuresProp={measures}
                setMeasuresProp={handleMeasuresSelected}
            />}
        </Box>
    );
};

export default AddFeaturesMeasures;
