import React from "react";
import {Box, Typography} from "@mui/material";
import theme from "theme";

const DataAssetDescription: React.FC<{ description: string }> = ({description}) => {
    return (
        <Box sx={{margin: "20px 0"}}>
            <Typography variant="subtitle2" sx={{color: theme.palette.customColor.grey, fontWeight: "400"}}>
                {description}
            </Typography>
        </Box>
    );
};

export default DataAssetDescription;
