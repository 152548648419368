import * as React from 'react';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import FormControlLabel from '@mui/material/FormControlLabel';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import {ArrowDownSymbols} from 'assets/icons';
import {useEffect} from "react";
import {SnowflakeSchemaNames} from "types";

const icon = <CheckBoxOutlineBlankIcon fontSize="small"/>;
const checkedIcon = <CheckBoxIcon fontSize="small"/>;

interface Schema {
    name: string;
    checked: boolean;
}

interface Tag {
    name: string;
    children: Schema[];
}

interface MultipleSelectCheckboxesProps {
    options: SnowflakeSchemaNames;
    schemasSelected: SnowflakeSchemaNames;
    handleOptionsSelected: (selectedTags: SnowflakeSchemaNames) => void,
}

const MultipleSelectCheckboxes: React.FC<MultipleSelectCheckboxesProps> = (
    {options, schemasSelected, handleOptionsSelected}) => {
    const [tags, setTags] = React.useState<Tag[]>([]);
    const [selectedTags, setSelectedTags] = React.useState<SnowflakeSchemaNames>([]);
    const [open, setOpen] = React.useState(false);
    const [searchValue, setSearchValue] = React.useState("");

    const handleToggle = (parentName: string, schema: Schema, checked: boolean) => {
        const tag = `${parentName}.${schema.name}`;
        setSelectedTags((prev) => {
            const newTags = [...prev];
            if (checked) {
                newTags.push(tag);
            } else {
                const index = newTags.findIndex((t) => t === tag);
                if (index !== -1) {
                    newTags.splice(index, 1);
                }
            }
            return newTags;
        });
    };

    const getOptionLabel = () => {
        const selectedChildren = tags.flatMap((tag) =>
            tag.children.filter((child) =>
                selectedTags.includes(`${tag.name}.${child.name}`)
            )
        );

        if (selectedChildren.length > 0) {
            const lastSelectedChild = selectedChildren[selectedChildren.length - 1];
            return `Selected ${lastSelectedChild.name}`;
        } else {
            return 'Choose database / schema';
        }
    };

    const isOptionSelected = (option: Tag) =>
        option.children.every((child) =>
            selectedTags.includes(`${option.name}.${child.name}`)
        );

    const createTags = (schemas: SnowflakeSchemaNames) => {
        const tagsDict: { [key: string]: { name: string; checked: boolean }[] } = {};

        // Iterate through each schema in the input
        schemas.forEach((schema) => {
            const [dbName, schemaName] = schema.split(".");

            // If the DB name is not in the tagsDict, add it
            if (!tagsDict[dbName]) {
                tagsDict[dbName] = [];
            }

            // Add the schema to the corresponding DB in the tagsDict
            tagsDict[dbName].push({name: schemaName, checked: false});
        });

        // Convert tagsDict to the desired output format
        const tags: Tag[] = Object.keys(tagsDict).map((dbName) => ({
            name: dbName,
            children: tagsDict[dbName],
        }));

        return tags;
    }

    useEffect(() => {
        if (options.length) {
            const tags: Tag[] = createTags(options);
            setTags(tags);
        }
    }, [options]);

    useEffect(() => {
        if (selectedTags.length === 0 && schemasSelected.length) {
            setSelectedTags(schemasSelected);
        }
    }, [schemasSelected]);

    useEffect(() => {
        if (selectedTags?.length) handleOptionsSelected(selectedTags);
    }, [selectedTags]);

    return (
        <Box sx={{width: '100%'}}>
            <Autocomplete
                noOptionsText=""
                popupIcon={<ArrowDownSymbols/>}
                multiple={false}
                open={open}
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}
                inputValue={searchValue}
                onInputChange={(event, newInputValue) => setSearchValue(newInputValue)}
                id="checkboxes-tags-demo"
                className="onboarding-scheme-selector"
                options={tags}
                disableCloseOnSelect
                getOptionLabel={getOptionLabel}
                filterOptions={(options, state) => {
                    if (state.inputValue === "") {
                        return options;
                    }
                    return options.filter((option) =>
                        option.children.some((child) =>
                            child.name.toLowerCase().includes(state.inputValue.toLowerCase())
                        )
                    );
                }}
                renderOption={(props, option: Tag) => (
                    <li {...props} key={`${option.name}-${option.children.map((child) => child.name).join('-')}`}>
                        <Box className="flex-box-col" sx={{width: '100%'}}>
                            <FormControlLabel
                                label={option.name}
                                control={
                                    <Checkbox
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        checked={isOptionSelected(option)}
                                        indeterminate={
                                            option.children.some((child) =>
                                                selectedTags.includes(`${option.name}.${child.name}`)
                                            ) && !isOptionSelected(option)
                                        }
                                        onChange={(event) => {
                                            const checked = event.target.checked;
                                            option.children.forEach((child) =>
                                                handleToggle(option.name, child, checked)
                                            );
                                            setOpen(true);

                                            const selectedChild = option.children.find((child) =>
                                                selectedTags.includes(`${option.name}.${child.name}`)
                                            );
                                            setSearchValue(selectedChild ? `Selected ${selectedChild.name}` : "");
                                        }}
                                    />


                                }
                            />
                            {option.children.map((child) => (
                                <Box key={child.name} className="flex-box-col" sx={{ml: 3}}>
                                    <FormControlLabel
                                        label={child.name}
                                        control={
                                            <Checkbox
                                                icon={icon}
                                                checkedIcon={checkedIcon}
                                                checked={selectedTags.includes(`${option.name}.${child.name}`)}
                                                onChange={(event) => {
                                                    const checked = event.target.checked;
                                                    const parentName = option.name;
                                                    const childName = child.name;
                                                    handleToggle(parentName, child, checked);
                                                    setOpen(true); // Keep the dropdown open
                                                }}
                                            />


                                        }
                                    />
                                </Box>
                            ))}
                        </Box>
                    </li>
                )}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        key="autocomplete-input"
                        id="autocomplete-input"
                        name="text"
                        label=""
                        placeholder="Choose database / schema"
                        InputProps={{
                            ...params.InputProps,
                            startAdornment: params.InputProps.startAdornment,
                            endAdornment: params.InputProps.endAdornment,
                        }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        value={params.inputProps.value ? `Selected: ${params.inputProps.value}` : ''}
                    />
                )}
                renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                        <TextField
                            variant="outlined"
                            label={getOptionLabel()}
                            {...getTagProps({index})}
                            key={`tag-${option.name}-${index}`}
                        />
                    ))
                }
            />
        </Box>
    );
};

export default MultipleSelectCheckboxes;
