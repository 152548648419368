import React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import {Box} from '@mui/material';
import {ArrowDownSymbols} from "assets/icons";

interface Props {
    value: { value: string, label: string } | null;
    inputValue: string;
    onChange: (event: any, newValue: { value: string, label: string } | null) => void;
    onInputChange: (event: any, newInputValue: string) => void;
}

const billBreakdownData = [
    {value: 'networx_prod.core.bill_breakdown_dim1', label: 'Bill 1'},
    {value: 'networx_prod.core.bill_breakdown_dim2', label: 'Bill 2'},
    {value: 'networx_prod.core.bill_breakdown_dim3', label: 'Bill 3'},
    {value: 'networx_prod.core.bill_breakdown_dim4', label: 'Bill 4'},
    {value: 'networx_prod.core.bill_breakdown_dim5', label: 'Bill 5'},
    {value: 'networx_prod.core.bill_breakdown_dim6', label: 'Bill 6'},
];

const AddEditBusinessEntitiesDimTableColumnSelect: React.FC<Props> = ({value, inputValue, onChange, onInputChange}) => {

    return (
        <Box className="autocomplete-container" sx={{width: 300, padding: 0}}>
            <Autocomplete
                popupIcon={<ArrowDownSymbols/>}
                value={value}
                inputValue={inputValue}
                onChange={onChange}
                onInputChange={onInputChange}
                autoHighlight
                id="combo-box-demo"
                options={billBreakdownData}
                getOptionLabel={(option) => option.value}
                isOptionEqualToValue={(option, value) => option.value === value.value}
                ListboxProps={{
                    sx: {
                        maxHeight: '344px',
                    }
                }}
                renderInput={(params) =>
                    <TextField
                        {...params}
                        className="autocomplete-select"
                        placeholder="Choose table"

                    />
                }
            />
        </Box>
    );
};

export default AddEditBusinessEntitiesDimTableColumnSelect;
