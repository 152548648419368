import React from "react";
import {Breadcrumbs, Stack, Typography} from "@mui/material";
import {Link} from "react-router-dom";
import theme from "theme";

interface BreadcrumbsProps {
    linkTo: string;
    linkText: string;
    secondText: string;
}

const BreadcrumbsComponent: React.FC<BreadcrumbsProps> = ({linkTo, linkText, secondText}) => {
    const breadcrumbs = [
        <Link key="1" to={linkTo} className="breadcrumbs-link">
            <Typography variant="subtitle2" sx={{color: theme.palette.customColor.grey}}>
                {linkText}
            </Typography>
        </Link>,
        <Typography key="2" variant="subtitle2" sx={{color: theme.palette.customColor.dark}}>
            {secondText}
        </Typography>,
    ];

    return (
        <Stack>
            <Breadcrumbs
                separator={<span className="material-symbols-outlined">chevron_right</span>}
                aria-label="breadcrumb"
                sx={{"& .material-symbols-outlined": {fontSize: "16px"}}}
            >
                {breadcrumbs}
            </Breadcrumbs>
        </Stack>
    );
};

export default BreadcrumbsComponent;
