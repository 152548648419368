import theme from "theme";
import {Typography} from "@mui/material";
import React from "react";
import {DataAssetFullResponse, DataAssetResponse} from "services/data-assets";

export const getDataAssetName = (asset: DataAssetResponse | DataAssetFullResponse) =>
    `${asset["db"]}.${asset["schema"]}.${asset["name"]}`.toLowerCase();


interface DataAssetNameProps {
    asset: DataAssetResponse | DataAssetFullResponse;
}

export const DataAssetName:React.FC<DataAssetNameProps> = ({asset}) => {
    return(
        <>
            <Typography variant="subtitle2" sx={{color: theme.palette.customColor.darkGrey}}>
                Selected Data Assets
            </Typography>
            <Typography
                variant="h5"
                className="flex-box-align-center select-text-step"
                sx={{color: theme.palette.customColor.slateGrey, mt: "10px"}}
            >
                <span className="material-symbols-outlined modal-icon-data">
                    database
                </span>
                {getDataAssetName(asset)}
            </Typography>
        </>
    )
}