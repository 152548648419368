import React from "react";
import {Button, ButtonProps} from "@mui/material";

interface ButtonComponentProps extends ButtonProps {
    variant: "text" | "outlined" | "contained" | "textPurple" | "containedGrey",
    label: string | React.ReactNode,
    onClick: (event: React.MouseEvent<HTMLButtonElement>) => void,
}

const ButtonComponent: React.FC<ButtonComponentProps> = ({variant, label, onClick, ...rest}) => {
    return (
        <Button variant={variant} onClick={onClick} {...rest}>
            {label}
        </Button>
    );
};

export default ButtonComponent;
