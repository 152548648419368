import React, {useState} from "react";
import "./onboarding-step-connect.component.scss";
import {
    Box,
    Button,
    FormControl,
    TextField,
    Typography,
    RadioGroup,
    FormControlLabel,
    Radio,
} from "@mui/material";
import {BigqueryLogo, PostgresqLogo, SnowflakeLogo} from "assets/icons";
import theme from "theme";
import {SnowflakeIntegrationPayload} from "../../../types";
import PasswordField from "../../shared/form/input-password.component";
import LoadingSpinner from "../../shared/LoadingSpinner";
import {Controller, useForm} from "react-hook-form";
import {connectSnowflake} from "../../../services/integrations";
import {handleRequestError} from "../../../utils/axios";

const radiobuttonData = [
    {label: "Snowflake", icon: <SnowflakeLogo/>},
    {label: "PostgreSQL", icon: <PostgresqLogo/>},
    {label: "BigQuery", icon: <BigqueryLogo/>}
];

interface FormField {
    id: number;
    label: string;
    placeholder: string;
    required: boolean;
    name: keyof SnowflakeIntegrationPayload;
    default?: string;
}

interface OnboardingStepConnectProps {
    handleNext: () => void;
    handleFormValues: (values: SnowflakeIntegrationPayload) => void;
    stepFormValues: SnowflakeIntegrationPayload | null;
}

const snowflakeFormFields: FormField[] = [
    {
        id: 1,
        name: "account",
        required: true,
        label: "Account",
        placeholder: "Account",
    },
    {
        id: 2,
        name: "user",
        required: true,
        label: "User",
        placeholder: "User"
    },
    {
        id: 3,
        name: "password",
        required: true,
        label: "Password",
        placeholder: "Password"
    },
    {
        id: 4,
        name: "role",
        required: true,
        label: "Role",
        placeholder: "Role"
    },
    {
        id: 5,
        name: "warehouse",
        required: true,
        label: "Warehouse",
        placeholder: "Warehouse"
    },
];

const OnboardingStepConnect: React.FC<OnboardingStepConnectProps> = ({
                                                                         handleNext,
                                                                         handleFormValues,
                                                                         stepFormValues
                                                                     }) => {
    const [selectedConnector, setSelectedConnector] = useState<string>("Snowflake");
    const [formError, setFormError] = useState<string | null>();
    const [isLoading, toggleLoadingState] = useState<boolean>(false);
    const {
        register,
        handleSubmit,
        control,
        formState: {errors},
    } = useForm();
    
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedConnector(event.target.value);
    };

    const onSubmit = async (data: any) => {
        const values: SnowflakeIntegrationPayload = {
            account: data.account,
            user: data.user,
            password: data.password,
            role: data.role,
            warehouse: data.warehouse
        }
        handleFormValues(values);
        toggleLoadingState(true);
        try {
            await connectSnowflake(values);
            setFormError(null);
            handleNext();
        } catch (error: any) {
            const message = handleRequestError(error)
            setFormError(message);
        } finally {
            toggleLoadingState(false);
        }
    };

    return (
        <Box>
            <Box className="flex-box-start onboarding-step-connect-box" p={5}>
                <Typography variant="h2">Connect Data</Typography>

                <Box className="flex-box-start" margin="24px -10px 32px -10px">
                    <RadioGroup
                        value={selectedConnector}
                        onChange={handleChange}
                        aria-label="Platform"
                        row
                    >
                        {radiobuttonData.map((radiobutton, index) => (
                            <FormControlLabel
                                sx={{
                                    "&, &.Mui-checked": {
                                        color: "transparent",
                                        backgroundColor: "transparent",
                                        padding: "12px 16px",
                                        borderRadius: "20px",
                                    },
                                }}
                                key={index}
                                value={radiobutton.label}
                                control={<Radio/>}
                                label={
                                    <Box className="flex-box-align-center">
                                        {radiobutton.icon}
                                        <Typography
                                            variant="subtitle2"
                                            sx={{color: theme.palette.customColor.dark, fontWeight: 600}}
                                        >
                                            {radiobutton.label}
                                        </Typography>
                                    </Box>
                                }
                                className="button-date"
                            />
                        ))}
                    </RadioGroup>
                </Box>

                <Box component="form"
                     onSubmit={handleSubmit(onSubmit)}
                     sx={{display: "flex", flexDirection: "column", gap: 4}}>
                    {snowflakeFormFields.map(formField => (
                        <FormControl key={formField.id}>
                            <Typography
                                variant="h5"
                                lineHeight="22px"
                                mb="10px"
                                sx={{color: theme.palette.customColor.darkGrey}}
                            >
                                {formField.label}
                            </Typography>
                            <Controller
                                name={formField.name}
                                control={control}
                                render={() =>
                                    formField.name === "password" ? (
                                        <PasswordField
                                            register={register}
                                            required={formField.required}
                                            name={formField.name}
                                            placeholder={formField.placeholder}
                                            defaultValue={stepFormValues ? stepFormValues[formField.name] : ""}
                                            error={!!errors[formField.name]}
                                            helperText={errors[formField.name] ? `${formField.label} is required` : ""}
                                        />
                                    ) : (
                                        <TextField
                                            {...register(formField.name, {required: formField.required})}
                                            placeholder={formField.placeholder}
                                            type="text"
                                            defaultValue={stepFormValues ? stepFormValues[formField.name] : ""}
                                            error={!!errors[formField.name]}
                                            helperText={errors[formField.name] ? `${formField.label} is required` : ""}
                                        />
                                    )}
                            />
                        </FormControl>
                    ))}
                    {formError && (
                        <Typography color="error" variant="body2">
                            {formError}
                        </Typography>
                    )}
                    <Box className="flex-box-end" marginTop="40px">
                        <Button type="submit" variant="contained" sx={{padding: "12px 16px"}}>
                            Test connection
                        </Button>
                    </Box>
                </Box>
            </Box>
            <LoadingSpinner open={isLoading}/>
        </Box>
    );
};

export default OnboardingStepConnect;
