import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

axiosInstance.interceptors.request.use(
  (config) => {
    // Modify config before request is sent
    return config;
  },
  (error) => {
    // Handle request error
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    // Any status code that lies within the range of 2xx causes this function to trigger
    return response;
  },
  (error) => {
    // Any status codes that falls outside the range of 2xx causes this function to trigger
    if (error.response?.data?.detail) {
      // assigning FastAPI's verbose message to AxiosError.message
      error.message = error.response?.data?.detail;
    } else if (error.response?.data) {
      error.message = error.response?.data;
    }
    return Promise.reject(error);
  }
);

export const transformKeys = function (responseData: { [key: string]: any }): { [key: string]: string } {
  /* Transform API response snake_case property names with JS-like camelCase properties */
  const data: { [key: string]: any } = {};

  for (const key in responseData) {
    const keys = key.split("_")
    const camelCaseKeys = keys.slice(1)?.map((key: string) => `${key[0].toUpperCase()}${key.slice(1)}`).join()
    const camelCaseKey = keys[0] + camelCaseKeys

    if (typeof (responseData[key]) === "object") {
      data[camelCaseKey] = transformKeys(responseData[key])
    } else {
      data[camelCaseKey] = responseData[key]
    }
  }

  return data
}

type ErrorMessage = string;

export const handleRequestError = (error: any): ErrorMessage => {
  return axios.isAxiosError(error) ? error.message : "An unexpected error occurred";
};

export default axiosInstance;
