import React from "react";
import DataAssetsComponent from "components/data-assets-component/data-assets.component";

const DataAssets = () => {
    return (
        <DataAssetsComponent/>
    );
};

export default DataAssets;
