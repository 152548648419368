import React from "react";
import {Backdrop, CircularProgress} from "@mui/material";
import theme from "theme";

class LoadingSpinner extends React.Component<{ open: boolean }> {
    render() {
        return <Backdrop
            sx={{
                color: theme.palette.customColor.white,
                zIndex: theme.zIndex.drawer + 1
            }}
            open={this.props.open}
        >
            <CircularProgress color="inherit"/>
        </Backdrop>;
    }
}

export default LoadingSpinner;