import axiosInstance, {transformKeys} from "../utils/axios";

export interface EntityResponse {
    id: string;
    name: string;
    description: string;
}

export const getEntities = async (branch: string | null): Promise<EntityResponse[]> => {
    const params = branch ? {branch: branch} : null;
    const response = await axiosInstance.get("/entities", {params});
    return response.data;
};

interface DataAsset {
    columnName: string;
    tableId: string;
}

export interface EntityDetailResponse {
    id: string;
    name: string;
    description: string;
    aliases: string[];
    relatedEntities: Record<string, string>;
    dataAssets: DataAsset[];
}


export const getEntity = async (entityId: string, branch: string | null): Promise<EntityDetailResponse> => {
    const params = branch ? {branch: branch} : null;
    const response = await axiosInstance.get(`/entities/${entityId}`, {params});
    response.data = transformKeys(response.data);
    return response.data;
};

export const getGitBranches = async (): Promise<string[]> => {
    const response = await axiosInstance.get("/integrations/git/branches");
    return response.data;
};