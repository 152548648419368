import React, {FC} from "react";
import {useNavigate, useLocation} from "react-router-dom";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import studioIcon from "assets/img/icon-studio.png";
import theme from "theme";
import {Link} from "react-router-dom";
import {useGlobalContext} from "context/global-context";
import "./header-menu.component.scss";

const iconMenuComponents = [
    <Link to="/entities" key="hub" className="material-symbols-outlined icon-menu" aria-label="Hub Icon">hub</Link>,
    <Link to="/data-assets" key="database" className="material-symbols-outlined icon-menu"
          aria-label="Database Icon">database</Link>,
    <Link to="/entities" key="import_contacts" className="material-symbols-outlined icon-menu"
          aria-label="Import Contacts Icon">import_contacts</Link>,
];

const listMenuItems = ["Entities", "Data assets", "Glossary"];
const routes = ["/entities", "/data-assets", "/entities"];

interface MenuProps {
    drawerOpen: boolean;
    handleDrawerClose: () => void;
    activeIndex: number | null;
    setActiveIndex: (index: number | null) => void;
}

const HeaderMenu: FC<MenuProps> = ({drawerOpen, handleDrawerClose, activeIndex, setActiveIndex}) => {
    const {isEditMode} = useGlobalContext();
    const navigate = useNavigate();
    const location = useLocation();

    React.useEffect(() => {
        const currentRouteIndex = routes.findIndex(route => route === location.pathname);
        if (currentRouteIndex !== -1) {
            setActiveIndex(currentRouteIndex);
        }
    }, [location.pathname, setActiveIndex]);

    const handleNavigation = (index: number) => {
        setActiveIndex(index);
        navigate(routes[index]);
    };

    return (
        <Drawer
            sx={{
                "& .MuiDrawer-paper": {
                    width: "125px",
                    boxSizing: "border-box",
                    backgroundColor: theme.palette.customColor.lightestGrey,
                    border: 0,
                    margin: "0 auto",
                    top: isEditMode ? "28px" : "0",
                },
            }}
            variant="persistent"
            anchor="left"
            open={drawerOpen}
            onClose={handleDrawerClose}
        >
            <List>
                <ListItem
                    disablePadding
                    sx={{
                        borderBottom: `1px solid ${theme.palette.customColor.softGrey}`,
                        margin: "0 auto 12px auto",
                        width: 61,
                    }}
                >
                    <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        sx={{margin: "12px 0"}}
                    >
                        <ListItemIcon sx={{minWidth: "auto"}}>
                            <img src={studioIcon} alt="studio"/>
                        </ListItemIcon>
                        <Typography
                            variant="body1"
                            sx={{color: theme.palette.customColor.slateGrey, paddingTop: "10px"}}
                            noWrap
                        >
                            STUDIO
                        </Typography>
                    </Box>
                </ListItem>
                {listMenuItems.map((text, index) => (
                    <ListItem key={text} disablePadding>
                        <ListItemButton
                            onClick={() => handleNavigation(index)}
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                margin: "12px 0",
                                "&:hover": {
                                    backgroundColor: "transparent",
                                },
                            }}
                        >
                            <ListItemIcon
                                className={activeIndex === index ? "active" : ""}
                                sx={{minWidth: "auto"}}
                            >
                                {iconMenuComponents[index]}
                            </ListItemIcon>
                            <Typography
                                variant="body1"
                                sx={{
                                    color: activeIndex === index ? theme.palette.customColor.purple : theme.palette.customColor.slateGrey,
                                    paddingTop: "10px",
                                    textAlign: "center",
                                }}
                                noWrap
                            >
                                {text}
                            </Typography>
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </Drawer>
    );
};

export default HeaderMenu;
