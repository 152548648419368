import React, {useState} from 'react';
import {Box, Paper, Typography} from '@mui/material';
import {useTheme} from '@mui/material/styles';
import {ArrowLeftSymbols, ArrowRightSymbols} from 'assets/icons';
import ButtonComponent from '../../shared/button/button.component';
import AddEditBusinessEntitiesDetails
    from '../add-edit-business-entities-details/add-edit-business-entities-details.component';
import AddEditBusinessEntitiesDimTable
    from '../add-edit-business-entities-dim-table/add-edit-business-entities-dim-table.component';
import AddEditBusinessEntitiesRelatedDatasets
    from '../add-edit-business-entities-related-datasets/add-edit-business-entities-related-datasets.component';
import AddEditBusinessEntitiesRelated
    from '../add-edit-business-entities-related/add-edit-business-entities-related.component';

export interface BusinessEntitiesModalCloseProps {
    handleCloseModal: () => void;
}

const AddEditBusinessEntitiesStepperView: React.FC<BusinessEntitiesModalCloseProps> = ({handleCloseModal}) => {
    const theme = useTheme();
    const [activeAddEditBusinessEntitiesSteps, setActiveAddEditBusinessEntitiesSteps] = useState(0);
    const maxSteps = 4;
    const [isActive, setIsActive] = useState(false);

    const handleNext = () => {
        setActiveAddEditBusinessEntitiesSteps((prevActiveStep) =>
            prevActiveStep < maxSteps - 1 ? prevActiveStep + 1 : prevActiveStep
        );
    };

    const handleBack = () => {
        setActiveAddEditBusinessEntitiesSteps((prevActiveStep) =>
            prevActiveStep > 0 ? prevActiveStep - 1 : prevActiveStep
        );
    };

    const AddEditBusinessEntitiesSteps = [
        {
            label: 'Entity details',
            subLabel: '',
            description: <AddEditBusinessEntitiesDetails isActive={isActive}/>,
        },
        {
            label: 'Entity DIM table',
            subLabel: (
                <>
                    The DIM dataset is a dataset that contains all the possible instances of
                    <br/>
                    that entity and for each instance it has only one record.
                </>
            ),
            description: <AddEditBusinessEntitiesDimTable showDimTableInfo/>,
        },
        {
            label: 'Related datasets',
            subLabel: (
                <>
                    Choose datasets that relate to "business entity name"
                    <br/>
                    You will be able to edit it later
                </>
            ),
            description: <AddEditBusinessEntitiesRelatedDatasets/>,
        },
        {
            label: 'Related entities',
            subLabel: 'Map the entity relationships for "business entity name"',
            description: <AddEditBusinessEntitiesRelated/>,
        },
    ];

    return (
        <Box className="flex-box-col-center"
             sx={{maxWidth: '1232px', width: '100%', marginTop: '32px', paddingBottom: '100px'}}>
            <Box className="flex-box-space-between" sx={{alignItems: 'center', width: '100%', marginBottom: '50px'}}>
                <Box sx={{width: '230px'}}>
                    <ButtonComponent
                        variant="text"
                        label="Back"
                        onClick={handleBack}
                        disabled={activeAddEditBusinessEntitiesSteps === 0}
                        startIcon={<ArrowLeftSymbols/>}
                    />
                </Box>

                <Box className="flex-box-col-center">

                    <Typography variant="h5" sx={{color: theme.palette.customColor.slateGrey}}>
                        Step {activeAddEditBusinessEntitiesSteps + 1} of {maxSteps}
                    </Typography>

                    <Typography
                        variant="h5"
                        sx={{fontSize: '18px', color: theme.palette.customColor.slateGrey, lineHeight: '26px'}}
                    >
                        Add Business Entity
                    </Typography>
                </Box>

                <Box className="flex-box-align-center" sx={{width: '230px'}}>
                    <ButtonComponent
                        variant="outlined"
                        label="Exit"
                        onClick={handleCloseModal}
                        sx={{marginRight: '16px'}}
                    />
                    <>
                        {activeAddEditBusinessEntitiesSteps !== maxSteps - 1 ? (
                            <ButtonComponent
                                variant="contained"
                                endIcon={<ArrowRightSymbols/>}
                                label="Next"
                                onClick={handleNext}
                            />
                        ) : (
                            <ButtonComponent
                                variant="contained"
                                endIcon={<ArrowRightSymbols/>}
                                label="Verify & Next"
                                onClick={handleNext}
                                disabled={false}
                            />
                        )}
                    </>
                </Box>
            </Box>

            <Box sx={{width: '100%'}}>
                <Paper
                    className="flex-box-col"
                    square
                    elevation={0}
                    sx={{alignItems: 'center', bgcolor: 'background.default'}}
                >
                    <Typography
                        variant="h3"
                        sx={{
                            fontSize: '42px',
                            color: theme.palette.customColor.dark,
                            lineHeight: '46px',
                            letterSpacing: '-0.84px',
                        }}
                    >
                        {AddEditBusinessEntitiesSteps[activeAddEditBusinessEntitiesSteps]?.label}
                    </Typography>

                    <Typography
                        variant="subtitle2"
                        sx={{
                            fontWeight: 400,
                            color: theme.palette.customColor.dimGrey,
                            textAlign: 'center',
                            margin: '24px 0 0 0',
                        }}
                    >
                        {AddEditBusinessEntitiesSteps[activeAddEditBusinessEntitiesSteps]?.subLabel}
                    </Typography>
                </Paper>

                <Box className="flex-box-center" sx={{width: '100%'}}>
                    {AddEditBusinessEntitiesSteps[activeAddEditBusinessEntitiesSteps]?.description}
                </Box>
            </Box>
        </Box>
    );
};

export default AddEditBusinessEntitiesStepperView;
