import React, {useEffect} from "react";
import {useForm, Controller} from "react-hook-form";
import {Box, TextField, FormControl, Typography} from "@mui/material";
import theme from "theme";
import {MeasureType} from "../../../../../../feature-types";
import ButtonComponent from "../../../../../../../shared/button/button.component";

interface FormField {
    id: number;
    label: string;
    placeholder: string;
    required: boolean;
    name: string;
}

const formFields: FormField[] = [
    {
        id: 1,
        label: "Name",
        placeholder: "Enter name measure",
        required: true,
        name: "name",
    },
    {
        id: 2,
        label: "Definition",
        placeholder: "e.g. - sum(total_sales)",
        required: true,
        name: "definition",
    },
    {
        id: 3,
        label: "Description",
        placeholder: "Enter description...",
        required: false,
        name: "description",
    },
];

interface AddFeaturesMeasuresStepCreateProps {
    onClose: () => void;
    onNewMeasureCreated: (measure: MeasureType) => void;
}

const AddFeaturesMeasuresStepCreate: React.FC<AddFeaturesMeasuresStepCreateProps> = ({
                                                                                         onClose,
                                                                                         onNewMeasureCreated
                                                                                     }) => {
    const {
        control,
        handleSubmit,
        formState: {errors, isValid},
    } = useForm({mode: "onChange"});

    const onSubmit = (data: any) => {
        onNewMeasureCreated({
            name: data.name,
            definition: data.definition,
            description: data.description,
            selected: false,
        });
        onClose();
    };

    return (
        <Box className="flex-box-col-start">
            <Box
                component="form"
                onSubmit={handleSubmit(onSubmit)}
                className="flex-box-col-start"
                sx={{width: "100%"}}
            >
                {formFields.map((formField) => (
                    <FormControl key={formField.id} sx={{width: "100%", marginBottom: "24px"}}>
                        <Typography
                            variant="h5"
                            lineHeight="22px"
                            mb="10px"
                            sx={{color: theme.palette.customColor.darkGrey}}
                        >
                            {formField.label}
                        </Typography>
                        <Controller
                            name={formField.name}
                            control={control}
                            rules={{required: formField.required}}
                            render={({field}) => {
                                if (formField.label === "Definition") {
                                    return (
                                        <TextField
                                            className="measures-input"
                                            {...field}
                                            placeholder={formField.placeholder}
                                            multiline
                                            minRows={2}
                                            error={!!errors[formField.name]}
                                            helperText={
                                                errors[formField.name]
                                                    ? `${formField.label} is required`
                                                    : ""
                                            }
                                        />
                                    );
                                }
                                if (formField.label === "Description") {
                                    return (
                                        <TextField
                                            className="measures-input"
                                            {...field}
                                            placeholder={formField.placeholder}
                                            multiline
                                            minRows={4}
                                            error={!!errors[formField.name]}
                                            helperText={
                                                errors[formField.name]
                                                    ? `${formField.label} is required`
                                                    : ""
                                            }
                                        />
                                    );
                                }
                                return (
                                    <TextField
                                        className="measures-input"
                                        {...field}
                                        placeholder={formField.placeholder}
                                        error={!!errors[formField.name]}
                                        helperText={
                                            errors[formField.name]
                                                ? `${formField.label} is required`
                                                : ""
                                        }
                                    />
                                );
                            }}
                        />
                    </FormControl>
                ))}
                <Box className="flex-box-end" sx={{marginTop: "8px"}}>
                    <ButtonComponent
                        type="button"
                        variant="text"
                        label="Cancel"
                        onClick={onClose}
                        sx={{fontSize: "14px", lineHeight: "22px"}}
                    />
                    <ButtonComponent
                        type="submit"
                        disabled={!isValid}
                        variant="contained"
                        label="Create"
                        onClick={() => {
                        }}
                        sx={{fontSize: "14px", lineHeight: "22px"}}
                    />
                </Box>
            </Box>
        </Box>
    );
};

export default AddFeaturesMeasuresStepCreate;
