import React, {useState, useEffect} from "react";
import "./onboarding-step-finish.component.scss";
import {Box, Typography, Button} from "@mui/material";
import MultipleSelectCheckmarks from "./onboarding-step-finish-checkbox.component";
import {SnowflakeSchemaNames, SnowflakeSchemasPayload} from "types";
import {connectSnowflakeSchemas, getSnowflakeSchemas} from "services/integrations";
import LoadingSpinner from "../../shared/LoadingSpinner";
import {handleRequestError} from "../../../utils/axios";


interface OnboardingStepConnectFinishProps {
    snowflakeSchemasSelected: SnowflakeSchemaNames;
    handleFormValues: (schemas: SnowflakeSchemaNames) => void;
    handleNext: () => void;
}

const OnboardingStepFinish: React.FC<OnboardingStepConnectFinishProps> = (
    {handleFormValues, handleNext, snowflakeSchemasSelected}
) => {
    const [errorMessage, setErrorMessage] = useState<string | null>();
    const [schemas, setSchemas] = React.useState<SnowflakeSchemaNames>([]);
    const [selectedSchemas, setSelectedSchemas] = React.useState<SnowflakeSchemaNames>([]);
    const [isLoading, toggleLoadingState] = useState<boolean>(false);

    useEffect(() => {
        const getSchemas = async () => {
            toggleLoadingState(true);
            try {
                const response = await getSnowflakeSchemas();
                setSchemas(response.schemas);
            } catch (error) {
                const message = handleRequestError(error);
                if (message) setErrorMessage(message)
            } finally {
                toggleLoadingState(false);
            }
        }
        if (schemas.length === 0 && !errorMessage) getSchemas();
    }, [schemas]);

    const onSave = async () => {
        const payload: SnowflakeSchemasPayload = {
            schemas: selectedSchemas,
        }
        toggleLoadingState(true);
        try {
            await connectSnowflakeSchemas(payload);
            setErrorMessage(null);
            handleNext();
        } catch (error) {
            const message = handleRequestError(error)
            if (message) setErrorMessage(message);
        } finally {
            toggleLoadingState(false);
        }
    }

    useEffect(() => {
        if (selectedSchemas?.length) handleFormValues(selectedSchemas);
    }, [selectedSchemas]);

    return (
        <Box>
            <Box
                className="onboarding-step-connect-box flex-box-col"
                width="100%"
                justifyContent="flex-start"
                p={5}
            >
                <Typography variant="h2" mb="32px">Do the magic ✨ <br/> Run Lynk discovery engine</Typography>

                <Box className="flex-box-col" component="form" sx={{mb: "10px"}}>
                    <Box mb={2} display="flex" flexDirection="column">
                        <Box display="flex" justifyContent="space-between">
                            <Typography variant="h5" color="#3C3B3F" lineHeight="22px" mb="10px">
                                Databases & Schemas
                            </Typography>
                        </Box>
                        <MultipleSelectCheckmarks options={schemas} schemasSelected={snowflakeSchemasSelected}
                                                  handleOptionsSelected={setSelectedSchemas}/>
                    </Box>
                </Box>
                <Box
                    className="flex-box-end"
                    width="100%"
                    marginTop="40px"
                >
                    <Button
                        onClick={onSave}
                        variant="contained"
                        sx={{padding: "12px 16px"}}
                        disabled={selectedSchemas.length === 0}
                    >
                        Test connection
                    </Button>
                </Box>
                {errorMessage && (
                    <Typography color="error" variant="body2">
                        {errorMessage}
                    </Typography>
                )}
            </Box>
            <LoadingSpinner open={isLoading}/>
        </Box>
    );
};

export default OnboardingStepFinish;
