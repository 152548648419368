import React, {useState} from "react";
import {Box} from "@mui/material";
import Typography from "@mui/material/Typography";
import {DataGrid, GridToolbar, GridColDef} from "@mui/x-data-grid";
import theme from "theme";
import ButtonComponent from "../../../shared/button/button.component";
import {
    EntityData
} from "../add-edit-business-entities-related-table-first/add-edit-business-entities-related-table-first.component";

const initialRows: EntityData[] = [
    {id: 1, entity: "User1", relationship: "One to one"},
    {id: 2, entity: "User2", relationship: "Many to one"},
    {id: 3, entity: "User3", relationship: "Many to many"},
    {id: 4, entity: "User4", relationship: "One to many"},
    {id: 5, entity: "User5", relationship: "One to one"},
    {id: 6, entity: "User6", relationship: "Many to one"},
    {id: 7, entity: "User7", relationship: "Many to many"},
];

const AddEditBusinessEntitiesRelatedTableSecond = () => {
    const [rows, setRows] = useState<EntityData[]>(initialRows.slice(0, 3));
    const [showAll, setShowAll] = useState<boolean>(false);
    const [deletedRows, setDeletedRows] = useState<Set<number>>(new Set());

    const handleDelete = (id: number) => {
        setRows((prevRows) => prevRows.filter((row) => row.id !== id));
        setDeletedRows((prevDeletedRows) => new Set(prevDeletedRows).add(id));
    };

    const handleClick = () => {
        if (!showAll) {
            setRows(initialRows.filter(row => !deletedRows.has(row.id)));
            setShowAll(true);
        } else {
            setRows(initialRows.filter(row => !deletedRows.has(row.id)).slice(0, 3));
            setShowAll(false);
        }
    };

    const hiddenRowCount = initialRows.filter(row => !deletedRows.has(row.id)).length - rows.length;

    const columns: GridColDef[] = [
        {
            field: "entity",
            headerName: "Entity",
            flex: 1,
            sortable: true,
        },
        {
            field: "relationship",
            headerName: "Relationship",
            flex: 1,
            sortable: false,
        },
        {
            field: "deleteAction",
            headerName: "",
            width: 30,
            sortable: false,
            renderCell: (params) => (
                <span className="material-symbols-outlined" style={{cursor: "pointer"}}
                      onClick={() => handleDelete(params.row.id)}>
                delete
                </span>
            ),
        },
    ];

    return (
        <Box className="flex-box-col-center" sx={{maxWidth: "620px", width: "100%"}}>
            <Box className="dim-table-style" sx={{width: "100%", marginTop: "32px"}}>
                <Typography
                    variant="h5"
                    sx={{
                        color: theme.palette.customColor.graphiteGrey,
                        fontWeight: 400,
                        lineHeight: "24px",
                        marginBottom: "16px",
                    }}
                >
                    Suggested by Lynk:
                </Typography>

                <DataGrid
                    hideFooter={true}
                    rows={rows}
                    columns={columns}
                    disableColumnFilter
                    disableColumnSelector
                    disableDensitySelector
                    disableAutosize={true}
                    className="entity-data-grid"
                    slots={{toolbar: GridToolbar}}
                    slotProps={{
                        toolbar: {
                            showQuickFilter: false,
                        },
                    }}
                    autoHeight
                    initialState={{
                        sorting: {
                            sortModel: [{field: "entity", sort: "asc"}],
                        },
                    }}
                />

                <ButtonComponent onClick={handleClick}
                                 variant="textPurple"
                                 label={showAll ? "Show less" : `Show ${hiddenRowCount} more suggestions`}
                                 sx={{fontSize: "14px"}}/>
            </Box>
        </Box>
    );
};

export default AddEditBusinessEntitiesRelatedTableSecond;
