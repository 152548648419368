import React, {useState} from "react";
import {Box, Card, CardActionArea, CardContent, Typography, Modal, Button, Popover} from "@mui/material";
import theme from "theme";
import "./add-features-card.scss";
import AddFeaturesModal from "../add-features-modal";
import AddFeaturesFormula from "../add-features-modal-content/add-features-formula/add-features-formula";
import ButtonComponent from "../../shared/button/button.component";
import {useGlobalContext} from "../../../context/global-context";
import {MetricType} from "../feature-types";

const cardIcons = ["analytics", "linked_services", "process_chart"];
const cardTextTitle = ["Metric", "First / Last", "Formula"];
const cardTextDescription = [
    "A Metric is a type of measure aggregation used in data analysis to quantify and evaluate specific aspects of a dataset",
    "With this method we are taking the attributes of the first (or last) appearance. For example: transaction status of the first transaction of a user",
    "A calculated field / composition of one or more existing features. Those features can be chosen from the dim, lifetime and matching entity-timeframe data assets",
];

interface AddFeaturesCardProps {
    onFeatureSaved: (metric: MetricType) => void;
}

const AddFeaturesCard: React.FC<AddFeaturesCardProps> = ({onFeatureSaved}) => {
    const [openModal, setOpenModal] = useState<null | number>(null);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const {isShowFeature} = useGlobalContext();

    const handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClosePopover = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    const handleOpenModal = (index: number) => {
        setOpenModal(index);
    };

    const handleCloseModal = () => {
        setOpenModal(null);
    };

    const renderModalContent = (index: number) => {
        switch (index) {
            case 0:
                return <AddFeaturesModal
                    type="metric"
                    handleCloseModal={handleCloseModal}
                    onFeatureSaved={onFeatureSaved}
                />;
            case 1:
                return <AddFeaturesModal
                    type="first/last"
                    onFeatureSaved={onFeatureSaved}
                    handleCloseModal={handleCloseModal}
                />;
            case 2:
                return <AddFeaturesFormula/>;
            default:
                return null;
        }
    };

    return (
        <Box className="flex-box-space-between" sx={{margin: "50px -16px"}}>
            {!isShowFeature && (
                <>
                    {cardTextTitle.map((title, index) => (
                        <Card key={index} draggable={false}
                              sx={{border: 0, boxShadow: "none", backgroundColor: "transparent"}}>
                            <CardActionArea
                                onClick={() => handleOpenModal(index)}
                                sx={{
                                    display: "flex",
                                    alignItems: "flex-start",
                                    boxSizing: "border-box",
                                    height: "249px",
                                    maxWidth: "390px",
                                    borderRadius: "16px",
                                    border: `1px solid ${theme.palette.customColor.lightGrey1}`,
                                    backgroundColor: theme.palette.customColor.white,
                                    boxShadow: "0px 4px 13px 0px rgba(97, 97, 97, 0.02)",
                                    margin: "0 16px",
                                    padding: "32px",
                                }}
                            >
                                <CardContent className="flex-box-col-center" sx={{padding: 0}}>
                                    <span
                                        className="material-symbols-outlined"
                                        style={{
                                            fontSize: "32px",
                                            color: theme.palette.customColor.purple,
                                        }}
                                    >
                                        {cardIcons[index]}
                                    </span>
                                    <Typography
                                        variant="subtitle2"
                                        textAlign="center"
                                        sx={{color: theme.palette.customColor.black, margin: "24px 0 10px 0"}}
                                    >
                                        {title}
                                    </Typography>
                                    <Typography
                                        variant="subtitle2"
                                        textAlign="center"
                                        sx={{fontWeight: 400, color: theme.palette.customColor.grey}}
                                    >
                                        {cardTextDescription[index]}
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    ))}
                </>
            )}
            {cardTextTitle.map((title, index) => (
                <Modal
                    key={index}
                    open={openModal === index}
                    onClose={handleCloseModal}
                    aria-labelledby={`${title}-modal-title`}
                    aria-describedby={`${title}-modal-description`}
                    className="container-modal-bg"
                >
                    <Box className="container-modal-small"
                         sx={{minHeight: "80svh", maxHeight: "80svh", overflowY: "auto"}}>
                        <Button className="modal-close" onClick={handleCloseModal}>
                            <span className="material-symbols-outlined modal-close-icon">close</span>
                        </Button>
                        {renderModalContent(index)}
                    </Box>
                </Modal>
            ))}

            <Box>
                {isShowFeature && (
                    <ButtonComponent onClick={handleOpenPopover} variant="contained" label="+ Add"/>
                )}
                <Popover
                    className="features-popover add-features-popover"
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClosePopover}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "center",
                    }}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                    }}
                >
                    <Box className="add-features-popover-box">
                        {cardTextTitle.map((title, index) => (
                            <Box
                                className="flex-box-align-center"
                                key={index}
                                sx={{
                                    padding: "12px",
                                    cursor: "pointer",
                                }}
                                onClick={() => {
                                    handleOpenModal(index);
                                    handleClosePopover();
                                }}
                            >
                                <span
                                    className="material-symbols-outlined"
                                    style={{
                                        fontSize: "24px",
                                        color: theme.palette.customColor.purple,
                                        marginRight: "16px",
                                    }}
                                >
                                    {cardIcons[index]}
                                </span>
                                <Box>
                                    <Typography
                                        variant="subtitle2"
                                        sx={{color: theme.palette.customColor.black}}
                                    >
                                        {title}
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        sx={{color: theme.palette.customColor.grey}}
                                    >
                                        {cardTextDescription[index]}
                                    </Typography>
                                </Box>
                            </Box>
                        ))}
                    </Box>
                </Popover>
            </Box>
        </Box>
    );
};

export default AddFeaturesCard;
