import React from 'react';
import {Box, FormControl, TextareaAutosize, TextField} from '@mui/material';
import Typography from '@mui/material/Typography';
import theme from 'theme';
import './add-edit-business-entities-details.component.scss';
import ButtonComponent from '../../shared/button/button.component';

const AddEditBusinessEntitiesDetails: React.FC<{ isActive: boolean }> = ({isActive}) => {
    const handleClick = () => {
        console.log('Button clicked');
    };

    return (
        <Box sx={{maxWidth: '620px', width: '100%', margin: '50px 0'}}>
            <FormControl fullWidth>
                <Box sx={{marginBottom: '32px'}}>
                    <Typography
                        variant="h5"
                        sx={{color: theme.palette.customColor.darkGrey}}
                        lineHeight="22px"
                        mb="10px"
                    >
                        Name
                    </Typography>
                    <TextField fullWidth required placeholder="Enter entity name" type="text"/>
                </Box>
                <Box>
                    <Typography
                        variant="h5"
                        sx={{color: theme.palette.customColor.darkGrey}}
                        lineHeight="22px"
                        mb="10px"
                    >
                        Description
                    </Typography>
                    <TextareaAutosize
                        className="text-area-connect-git text-area-size"
                        minRows={7}
                        placeholder="Your entity description..."
                    />
                </Box>
                <Box sx={{marginTop: '32px'}}>
                    <Typography variant="subtitle2" sx={{color: theme.palette.customColor.dark}}>
                        Also known as
                    </Typography>
                    <Box sx={{marginTop: '10px'}}>
                        <Box className="flex-box-align-center" sx={{marginBottom: '10px'}}>
                            <TextField
                                fullWidth
                                required
                                placeholder="Enter entity name"
                                type="text"
                                sx={{marginRight: '16px'}}
                            />
                            <span className="material-symbols-outlined">delete</span>
                        </Box>
                    </Box>
                    {!isActive && (
                        <ButtonComponent
                            variant="textPurple"
                            label="+ Add new"
                            onClick={handleClick}
                            sx={{paddingLeft: 0}}
                        />
                    )}
                </Box>
            </FormControl>
        </Box>
    );
};

export default AddEditBusinessEntitiesDetails;
