import React, {useEffect, useMemo, useState} from "react";
import {Box, Typography, Modal, Button} from "@mui/material";
import "./add-features-measures-modal.scss";
import theme from "theme";
import ButtonComponent from "../../../../../../shared/button/button.component";
import AddFeaturesMeasuresStepCreate from "./add-features-measures-step-create/add-features-measures-create";
import {ArrowLeftSymbols} from "assets/icons";
import {containsText} from "utils/contains-text";
import {DataAssetFullResponse} from "../../../../../../../services/data-assets";
import {DataAssetName} from "../../../../../data-asset-name";
import {MeasureType, MetricType} from "../../../../../feature-types";
import SearchComponent from "../../../../../../shared/search/search.component";

interface AddFeaturesMeasuresModalProps {
    open: boolean;
    onClose: () => void;
    selectedAsset: DataAssetFullResponse;
    measuresProp?: MeasureType[];
    setMeasuresProp: (measuresDatas: MeasureType[]) => void;
}

const AddFeaturesMeasuresModal: React.FC<AddFeaturesMeasuresModalProps> = ({
                                                                               open,
                                                                               onClose,
                                                                               selectedAsset,
                                                                               measuresProp = [],
                                                                               setMeasuresProp,
                                                                           }) => {
    const [searchValue, setSearchValue] = useState("");
    const [filteredMeasures, setFilteredMeasures] = useState<MetricType["measures"]>();
    const [showCreateSection, setShowCreateSection] = useState(false);
    const [isFormValid, setIsFormValid] = useState(false);
    const [checkedCount, setCheckedCount] = useState(0);
    const [addMeasureLabel, setAddMeasureLabel] = useState("Add measure");
    const [measuresSelectedState, setMeasuresSelectedState] = useState<MeasureType[]>(measuresProp);

    const handleOpenMeasuresStep = () => {
        setShowCreateSection(!showCreateSection);
    };

    const saveSelectedMeasures = () => {
        setMeasuresProp(measuresSelectedState); // update selected measures in parent state
        onClose();
        setSearchValue("");
    };

    const closeMeasuresSelect = () => {
        setMeasuresSelectedState(measuresProp); // restore parent state of selected measures
        onClose();
        setSearchValue("");
    }

    const toggleMeasureSelectedState = (measureName: MeasureType["name"]) => {
        const updatedMeasures: MeasureType[] = measuresSelectedState.map(
            m => m.name === measureName ? {...m, selected: !m.selected} : m
        );
        setMeasuresSelectedState(updatedMeasures);
    }

    const handleNewMeasureCreated = (measure: MeasureType) => {
        // TODO: ensure each measure name is unique
        setMeasuresSelectedState([...measuresSelectedState, measure]) // handle local selected stated separately
        setMeasuresProp([...measuresProp, measure]) // handle parent state
    }

    useEffect(() => {
        if (searchValue && Boolean(measuresSelectedState)) {
            setFilteredMeasures(
                measuresSelectedState.filter((m) => containsText(m.name, searchValue)) as MetricType["measures"]
            );
        } else if (Boolean(measuresSelectedState)) {
            setFilteredMeasures(measuresSelectedState as MetricType["measures"]);
        }
    }, [measuresSelectedState, searchValue]);

    useEffect(() => {
        const count = measuresSelectedState.filter((m) => m.selected === true).length;
        setCheckedCount(count);
        setAddMeasureLabel(count > 0 ? `Add measure (${count})` : "Add measure");
    }, [measuresSelectedState]);

    return (
        <Modal open={open} onClose={onClose} className="container-modal-bg-none">
            <Box
                className={!showCreateSection ? "container-modal-small container-modal-small-custom" : "container-modal-small"}
            >
                {!showCreateSection ? (
                    <>
                        <Box className="flex-box-align-center">
                            <SearchComponent
                                searchValue={searchValue}
                                setSearchValue={setSearchValue}
                            />
                            <ButtonComponent
                                onClick={handleOpenMeasuresStep}
                                variant="textPurple"
                                label="+ Create"
                                sx={{
                                    fontSize: "14px",
                                    lineHeight: "22px",
                                    margin: "0 0 0 16px",
                                    padding: 0,
                                }}
                            />
                        </Box>
                        <Box
                            sx={{
                                maxHeight: "390px",
                                overflowY: "auto",
                                marginTop: "8px",
                            }}
                        >
                            {filteredMeasures?.map((measure, index) => (
                                <Box
                                    key={measure.name}
                                    className="flex-box-align-center"
                                    sx={{
                                        cursor: "pointer",
                                        borderRadius: "10px",
                                        backgroundColor: Boolean(measure.selected)
                                            ? theme.palette.customColor.purpleLight
                                            : "transparent",
                                        marginBottom: "3px",
                                        padding: "8px 16px",
                                        "&:last-child": {
                                            marginBottom: 0,
                                        },
                                    }}
                                    onClick={() => toggleMeasureSelectedState(measure.name)}
                                >
                                    <span
                                        className="material-symbols-outlined"
                                        style={{
                                            color: Boolean(measure.selected)
                                                ? theme.palette.customColor.purple
                                                : "inherit",
                                        }}
                                    >
                                        match_case
                                    </span>
                                    <Box sx={{ml: "10px"}}>
                                        <Typography
                                            variant="subtitle2"
                                            sx={{
                                                color: Boolean(measure.selected)
                                                    ? theme.palette.customColor.purple
                                                    : theme.palette.customColor.darkGrey,
                                                mb: "2px",
                                            }}
                                        >
                                            {measure.name}
                                        </Typography>
                                        <Typography
                                            variant="h5"
                                            sx={{color: theme.palette.customColor.grey}}
                                        >
                                            {measure.definition}
                                        </Typography>
                                    </Box>
                                    {Boolean(measure.selected) && (
                                        <span
                                            className="material-symbols-outlined"
                                            style={{
                                                marginLeft: "auto",
                                                color: theme.palette.customColor.purple,
                                            }}
                                        >
                                            check
                                        </span>
                                    )}
                                </Box>
                            ))}
                            {(searchValue && filteredMeasures?.length === 0) &&
                                <Typography variant="body2">No measure found</Typography>
                            }
                        </Box>
                        <Box className="flex-box-end" sx={{marginTop: "8px"}}>
                            <ButtonComponent
                                variant="text"
                                label="Cancel"
                                onClick={closeMeasuresSelect}
                                sx={{fontSize: "14px", lineHeight: "22px"}}
                            />
                            <ButtonComponent
                                disabled={checkedCount === 0}
                                variant="contained"
                                label={addMeasureLabel}
                                onClick={saveSelectedMeasures}
                                sx={{fontSize: "14px", lineHeight: "22px"}}
                            />
                        </Box>
                    </>
                ) : (
                    <Box>
                        <Button className="modal-close" onClick={onClose}>
                            <span className="material-symbols-outlined modal-close-icon">
                                close
                            </span>
                        </Button>

                        <Box>
                            <Box className="flex-box-align-center">
                                <ButtonComponent
                                    onClick={handleOpenMeasuresStep}
                                    variant="text"
                                    label={<ArrowLeftSymbols/>}
                                    sx={{
                                        minWidth: "auto",
                                        fontSize: "14px",
                                        lineHeight: "22px",
                                        margin: 0,
                                        padding: 0,
                                    }}
                                />
                                <Typography
                                    variant="h4"
                                    sx={{width: "100%", textAlign: "center", fontWeight: 600}}
                                >
                                    Create Measure
                                </Typography>
                            </Box>

                            <Box sx={{margin: "24px 0"}}>
                                <DataAssetName asset={selectedAsset}/>
                            </Box>
                        </Box>
                        <AddFeaturesMeasuresStepCreate
                            onClose={() => setShowCreateSection(false)}
                            onNewMeasureCreated={handleNewMeasureCreated}
                        />
                    </Box>
                )}
            </Box>
        </Modal>
    );
};

export default AddFeaturesMeasuresModal;
