import React from "react";
import {Box} from "@mui/material";

import AddEditBusinessEntitiesStepperEdit, {BusinessEntitiesModalCloseProps}
    from "./add-edit-business-entities-stepper/add-edit-business-entities-stepper-edit.component";

const EditBusinessEntityComponent: React.FC<BusinessEntitiesModalCloseProps> = ({handleCloseModal}) => {
    return (
        <Box
            className="flex-box-col-center"
        >
            <AddEditBusinessEntitiesStepperEdit handleCloseModal={handleCloseModal}/>
        </Box>

    );
};

export default EditBusinessEntityComponent;
