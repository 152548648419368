import React from "react";
import {Box, Typography} from "@mui/material";
import ButtonComponent from "../../shared/button/button.component";
import {EditSymbols} from "assets/icons";
import theme from "theme";
import {Link} from "react-router-dom";

export interface BusinessEntitiesContentInfoProps {
    name: string;
    description: string;
    isEditMode: boolean;
    onClickEdit: () => void;
    handleChange: () => void;
}

const BusinessEntitiesInfo: React.FC<BusinessEntitiesContentInfoProps> = ({
                                                                              name,
                                                                              description,
                                                                              isEditMode,
                                                                              onClickEdit,
                                                                              handleChange,
                                                                          }) => {
    return (
        <Box
            className="business-entity-info"
            sx={{
                borderRadius: "16px",
                border: "1px solid #DDD",
                boxShadow: "0 4px 13px 0 rgba(97, 97, 97, 0.02)",
                padding: "32px",
                height: "180px",
            }}
        >
            <Typography
                variant="h4"
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    color: theme.palette.customColor.black,
                    textTransform: "capitalize",
                    "& .material-symbols-outlined": {
                        width: "20px",
                        height: "20px",
                        display: isEditMode ? "block" : "none",
                    },
                }}>
                {name.replace("_", " ")}
                <span className="material-symbols-outlined">delete</span>
            </Typography>

            <Typography variant="h5" sx={{color: theme.palette.customColor.grey, margin: "16px 0 48px 0"}}>
                {description}
            </Typography>

            {isEditMode ? (
                <ButtonComponent onClick={onClickEdit} variant="containedGrey" label="Edit" startIcon={<EditSymbols/>}/>
            ) : (
                <Link to={`/entities/${name}`}>
                    <ButtonComponent onClick={handleChange} variant="containedGrey" label="View"/>
                </Link>
            )}
        </Box>
    );
};

export default BusinessEntitiesInfo;
