import React, {useState} from "react";
import {Box, Typography, Modal, Button} from "@mui/material";
import theme from "theme";
import ButtonComponent from "../../../../../../shared/button/button.component";
import {ArrowLeftSymbols} from "assets/icons";
import {containsText} from "utils/contains-text";
import SearchComponent from "../../../../../../shared/search/search.component";
import {DataAssetFullResponse} from "../../../../../../../services/data-assets";
import {DataAssetName} from "../../../../../data-asset-name";

interface FirstLastDataProps {
    id: number;
    title: string;
}

interface AddFeaturesFirstLastModalProps {
    open: boolean;
    onClose: () => void;
    selectedAsset: DataAssetFullResponse | null;
    selectedFirstLastDatas: FirstLastDataProps[];
    setSelectedFirstLastDatas: (FirstLastDatas: FirstLastDataProps[]) => void;
}

const AddFeaturesFirstLastModal: React.FC<AddFeaturesFirstLastModalProps> = ({
                                                                                 open,
                                                                                 onClose,
                                                                                 selectedAsset,
                                                                                 selectedFirstLastDatas,
                                                                                 setSelectedFirstLastDatas,
                                                                             }) => {
    const initialCheckedState = Array(10).fill(false);
    const [checkedFirstLastDatas, setCheckedFirstLastDatas] = useState(initialCheckedState);
    const [searchValue, setSearchValue] = useState("");
    const [showCreateSection, setShowCreateSection] = useState(false);
    const [isFormValid, setIsFormValid] = useState(false);

    const handleCheckboxChange = (index: number) => {
        const newCheckedFirstLastDatas = [...checkedFirstLastDatas];
        newCheckedFirstLastDatas[index] = !newCheckedFirstLastDatas[index];
        setCheckedFirstLastDatas(newCheckedFirstLastDatas);
    };

    const handleOpenFirstLastStep = () => {
        setShowCreateSection(!showCreateSection);
    };

    const handleAddSelectedFirstLastDatas = () => {
        const selected = FirstLastDatas.filter((_, index) => checkedFirstLastDatas[index]);
        setSelectedFirstLastDatas(selected);
        onClose();
    };

    const FirstLastDatas = Array.from({length: 10}, (_, index) => ({
        id: index,
        title: `FirstLastData ${index + 1}`,
        showIcon: checkedFirstLastDatas[index],
    }));

    const filteredFirstLastDatas = FirstLastDatas.filter((FirstLastData) =>
        containsText(FirstLastData.title, searchValue)
    );

    return (
        <Modal open={open} onClose={onClose} className="container-modal-bg-none">
            <Box className={`container-modal-small ${!showCreateSection ? "container-modal-sx-custom" : ""}`}>
                {!showCreateSection ? (
                    <>
                        <Box className="flex-box-align-center">
                            <SearchComponent
                                searchValue={searchValue}
                                setSearchValue={setSearchValue}/>
                        </Box>
                        <Box
                            sx={{
                                maxHeight: "390px",
                                overflowY: "auto",
                                marginTop: "16px",
                            }}
                        >
                            <Typography variant="h5" sx={{color: theme.palette.customColor.grey, paddingLeft: "12px"}}>
                                All Properties
                            </Typography>
                            {filteredFirstLastDatas.slice(0, 10).map((FirstLastData, index) => (
                                <Box
                                    key={FirstLastData.id}
                                    className="flex-box-align-center"
                                    sx={{
                                        cursor: "pointer",
                                        borderRadius: "10px",
                                        backgroundColor: checkedFirstLastDatas[index]
                                            ? theme.palette.customColor.purpleLight
                                            : "transparent",
                                        marginBottom: "3px",
                                        padding: "8px 16px",
                                        "&:last-child": {
                                            marginBottom: 0,
                                        },
                                    }}
                                    onClick={() => handleCheckboxChange(index)}
                                >
                                    <span
                                        className="material-symbols-outlined"
                                        style={{
                                            color: checkedFirstLastDatas[index]
                                                ? theme.palette.customColor.purple
                                                : "inherit",
                                        }}
                                    >
                                        match_case
                                    </span>
                                    <Box sx={{ml: "10px"}}>
                                        <Typography
                                            variant="subtitle2"
                                            sx={{
                                                color: checkedFirstLastDatas[index]
                                                    ? theme.palette.customColor.purple
                                                    : theme.palette.customColor.darkGrey,
                                            }}
                                        >
                                            {FirstLastData.title}
                                        </Typography>
                                    </Box>
                                    {checkedFirstLastDatas[index] && (
                                        <span
                                            className="material-symbols-outlined"
                                            style={{
                                                marginLeft: "auto",
                                                color: theme.palette.customColor.purple,
                                            }}
                                        >
                                            check
                                        </span>
                                    )}
                                </Box>
                            ))}
                        </Box>
                    </>
                ) : (
                    <Box>
                        <Button className="modal-close" onClick={onClose}>
                            <span className="material-symbols-outlined modal-close-icon">
                                close
                            </span>
                        </Button>
                        <Box>
                            <Box className="flex-box-align-center">
                                <ButtonComponent
                                    onClick={handleOpenFirstLastStep}
                                    variant="text"
                                    label={<ArrowLeftSymbols/>}
                                    sx={{
                                        minWidth: "auto",
                                        fontSize: "14px",
                                        lineHeight: "22px",
                                        margin: 0,
                                        padding: 0,
                                    }}
                                />
                                <Typography
                                    variant="h4"
                                    sx={{width: "100%", textAlign: "center", fontWeight: 600}}
                                >
                                    Add Features First/Last
                                </Typography>
                            </Box>
                            {selectedAsset && <Box sx={{margin: "24px 0"}}>
                                <DataAssetName asset={selectedAsset}/>
                            </Box>}
                        </Box>
                    </Box>
                )}
                <Box className="flex-box-end" sx={{marginTop: "8px"}}>
                    <ButtonComponent
                        variant="text"
                        label="Cancel"
                        onClick={onClose}
                        sx={{fontSize: "14px", lineHeight: "22px"}}
                    />
                    <ButtonComponent
                        disabled={!showCreateSection ? !checkedFirstLastDatas.some((checked) => checked) : !isFormValid}
                        variant="contained"
                        label="Add fields"
                        onClick={handleAddSelectedFirstLastDatas}
                        sx={{fontSize: "14px", lineHeight: "22px"}}
                    />
                </Box>
            </Box>
        </Modal>
    );
};

export default AddFeaturesFirstLastModal;
