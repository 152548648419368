import React, {useEffect, useState} from "react";
import {Box, Typography} from "@mui/material";
import {
    DataGrid,
    GridRenderCellParams,
    useGridApiRef,
} from "@mui/x-data-grid";
import CustomTableSearch from "../../shared/table/table-search/table-search.component";
import CustomTablePagination
    from "../../shared/table/table-pagination/table-pagination.component";
import {Link} from "react-router-dom";
import "./data-assets-table.component.scss"
import {DataAssetsPaginationResponse, getDataAssets, PaginationParams} from "services/data-assets";
import {getDataAssetName} from "../../add-features-component/data-asset-name";

type PaginationModel = {
    pageSize: number,
    page: number,
};

const DataAssetsTable: React.FC = () => {
    const PAGE_SIZE = 5;
    const columns = [
        {
            field: "nameSchema",
            headerName: "Name (db.schema.name)",
            flex: 1,
            sortable: true,
            renderCell: (params: GridRenderCellParams) => (
                <Link to={`/data-assets/${params.row.id.toLowerCase()}`} className="link-no-decoration">
                    {params.value}
                </Link>
            ),
        },
        {
            field: "dbSchema",
            headerName: "db.schema",
            flex: 1,
            sortable: false,
            renderCell: (params: GridRenderCellParams) => (
                <Typography
                    className="data-asset-name-db"
                    variant="h5"
                >
                    {params.value}
                </Typography>
            ),
        },
        {
            field: "key",
            headerName: "Key",
            flex: 1,
            sortable: false,
            renderCell: (params: GridRenderCellParams) => (
                <Typography
                    className="data-asset-cell-text"
                    variant="h5"
                >
                    {params.value}
                </Typography>
            ),
        },
        {
            field: "businessKey",
            headerName: "Business Key",
            flex: 1,
            sortable: false,
            renderCell: (params: GridRenderCellParams) => (
                <Typography
                    className="data-asset-cell-text"
                    variant="h5"
                >
                    {params.value}
                </Typography>
            ),
        },
    ];
    const apiRef = useGridApiRef();
    const [dataAssets, setDataAssets] = useState<any[]>([]);
    const [searchValue, setSearchValue] = useState<string>("");
    const [paginationModel, setPaginationModel] = useState<PaginationModel>({
        pageSize: PAGE_SIZE,
        page: 0,
    });

    const fetchData = async (searchValue: string, paginationModel: PaginationModel): Promise<void> => {
        const params: PaginationParams = {
            page: paginationModel.page === 0 ? 1 : paginationModel.page + 1, // DataGrid uses 0-based page count
            pageSize: paginationModel.pageSize,
            tableId: searchValue,
        }
        try {
            const response: DataAssetsPaginationResponse = await getDataAssets(params);
            const assets = Object.values(response["assets"]).map(
                (asset) => ({
                    "id": getDataAssetName(asset),
                    "nameSchema": asset["name"],
                    "dbSchema": asset["db"] ? `${asset["db"]}.${asset["schema"]}` : asset["schema"],
                    ...asset["keys"] && {"key": Object.values(asset["keys"]).join(", ")},
                    ...asset["businessKeys"] && {"businessKey": Object.values(asset["businessKeys"]).join(", ")},
                }))
            setDataAssets(assets);
            apiRef.current.setRowCount(response["totalRecords"])
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        fetchData(searchValue, paginationModel);
    }, [searchValue, paginationModel]);

    return (
        <Box className="flex-box-start" flexDirection="column">
            <Box style={{width: "100%"}} margin="24px 0 25px 0">
                <CustomTableSearch value={searchValue} onChange={(e) => setSearchValue(e.target.value)}/>
                <DataGrid
                    apiRef={apiRef}
                    className="entity-data-grid"
                    rows={dataAssets}
                    columns={columns}
                    paginationMode="server"
                    paginationModel={paginationModel}
                    onPaginationModelChange={setPaginationModel}
                    autoHeight
                    initialState={{
                        sorting: {
                            sortModel: [{field: "nameSchema", sort: "asc"}],
                        },
                    }}
                    slots={{
                        pagination: CustomTablePagination,
                    }}
                />
            </Box>
        </Box>
    );
};

export default DataAssetsTable;
