import axiosInstance, {transformKeys} from "../utils/axios";
import {PaginationResponse} from "../types";

export interface PaginationParams {
    pageSize?: number,
    page?: number,
    tableId?: string,
}

export interface DataAssetResponse {
    name: string;
    schema?: string;
    db?: string;
    description: string;
    entities: string[];
    keys: string[];
    businessKeys: string[];
}

export interface DataAssetsPaginationResponse extends PaginationResponse {
    assets: DataAssetResponse[]
}


export const getDataAssets = async (params: PaginationParams | null): Promise<DataAssetsPaginationResponse> => {
    const queryParams = params ? {
        current_page: params.page,
        items_per_page: params.pageSize,
        table_id: params.tableId
    } : {}
    const response = await axiosInstance.get('/assets', {params: queryParams});
    response.data = transformKeys(response.data);
    return response.data;
};

export interface DataAssetColumn {
    name: string;
    description: string;
    type: string;
}

export interface DataAssetFullResponse extends DataAssetResponse {
    columns: DataAssetColumn[]
}

export const getDataAssetById = async (assetId: string): Promise<DataAssetFullResponse> => {
    const response = await axiosInstance.get(`/assets/${assetId}`);
    return response.data;
};
