import React, {useState} from 'react';
import {Box, Paper, Typography} from '@mui/material';
import {useTheme} from '@mui/material/styles';
import {ArrowLeftSymbols, ArrowRightSymbols} from 'assets/icons';
import ButtonComponent from '../../shared/button/button.component';
import AddEditBusinessEntitiesDetails
    from '../add-edit-business-entities-details/add-edit-business-entities-details.component';
import AddEditBusinessEntitiesDimTable
    from '../add-edit-business-entities-dim-table/add-edit-business-entities-dim-table.component';
import AddEditBusinessEntitiesRelatedDatasets
    from '../add-edit-business-entities-related-datasets/add-edit-business-entities-related-datasets.component';
import AddEditBusinessEntitiesRelated
    from '../add-edit-business-entities-related/add-edit-business-entities-related.component';
import EditModeBusinessEntities from './edit-mode-business-entity/edit-mode-business-entities.component';

export interface BusinessEntitiesModalCloseProps {
    handleCloseModal: () => void;
}

const AddEditBusinessEntitiesStepperEdit: React.FC<BusinessEntitiesModalCloseProps> = ({handleCloseModal}) => {
    const theme = useTheme();
    const [activeAddEditBusinessEntitiesSteps, setActiveAddEditBusinessEntitiesSteps] = useState(0);
    const maxSteps = 4;
    const [isActive, setIsActive] = useState(false);
    const [showDimTableInfo, setShowDimTableInfo] = useState(false); // State to control visibility

    const handleChange = () => {
        setIsActive(!isActive);
    };

    const handleNext = () => {
        setActiveAddEditBusinessEntitiesSteps((prevActiveStep) =>
            prevActiveStep < maxSteps - 1 ? prevActiveStep + 1 : prevActiveStep
        );
    };

    const handleBack = () => {
        setActiveAddEditBusinessEntitiesSteps((prevActiveStep) =>
            prevActiveStep > 0 ? prevActiveStep - 1 : prevActiveStep
        );
    };

    const AddEditBusinessEntitiesSteps = [
        {
            label: 'Entity details',
            subLabel: '',
            description: <AddEditBusinessEntitiesDetails isActive={isActive}/>,
        },
        {
            label: 'Entity DIM table',
            subLabel: (
                <>
                    The DIM dataset is a dataset that contains all the possible instances of
                    <br/>
                    that entity and for each instance it has only one record.
                </>
            ),
            description: <AddEditBusinessEntitiesDimTable showDimTableInfo={showDimTableInfo}/>,
        },
        {
            label: 'Related datasets',
            subLabel: (
                <>
                    Choose datasets that relate to "business entity name"
                    <br/>
                    You will be able to edit it later
                </>
            ),
            description: <AddEditBusinessEntitiesRelatedDatasets/>,
        },
        {
            label: 'Related entities',
            subLabel: 'Map the entity relationships for "business entity name"',
            description: <AddEditBusinessEntitiesRelated/>,
        },
    ];

    return (
        <Box className="flex-box-col-center"
             sx={{maxWidth: '1232px', width: '100%', marginTop: '32px', paddingBottom: '100px'}}>
            <Box className="flex-box-space-between" sx={{alignItems: 'center', width: '100%', marginBottom: '20px'}}>
                <Box sx={{width: '200px'}}>
                    <ButtonComponent
                        variant="text"
                        label="Back"
                        onClick={handleBack}
                        disabled={activeAddEditBusinessEntitiesSteps === 0}
                        startIcon={<ArrowLeftSymbols/>}
                    />
                </Box>

                <Box className="flex-box-col-center">
                    <Typography variant="h2" sx={{color: theme.palette.customColor.dark}}>
                        You are editing:
                    </Typography>
                </Box>

                <Box className="flex-box-align-center" sx={{width: '230px'}}>
                    <ButtonComponent
                        variant="contained"
                        endIcon={<ArrowRightSymbols/>}
                        label="Commit changes"
                        onClick={handleNext}
                    />
                </Box>
            </Box>

            <Box sx={{width: '100%'}}>
                <Paper
                    className="flex-box-col"
                    square
                    elevation={0}
                    sx={{alignItems: 'center', bgcolor: 'background.default'}}
                >
                    <EditModeBusinessEntities activeStep={activeAddEditBusinessEntitiesSteps}/>
                </Paper>

                <Box className="flex-box-center" sx={{width: '100%'}}>
                    {AddEditBusinessEntitiesSteps[activeAddEditBusinessEntitiesSteps]?.description}
                </Box>
            </Box>
        </Box>
    );
};

export default AddEditBusinessEntitiesStepperEdit;
