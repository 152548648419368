import React from "react";
import {Box, Modal} from "@mui/material";
import AddEditBusinessEntitieEditComponent
    from "../../add-edit-business-entity-component/add-edit-business-entitie-edit.component";

interface BusinessEntitiesModalProps {
    openEdit: boolean;
    onClose: () => void;
}

const BusinessEntityModalEdit: React.FC<BusinessEntitiesModalProps> = ({openEdit, onClose}) => {
    return (
        <Modal
            open={openEdit}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={{display: "flex", justifyContent: "center", alignItems: "center", height: "100vh"}}>
                <Box className="container-modal">
                    <AddEditBusinessEntitieEditComponent handleCloseModal={onClose}/>
                </Box>
            </Box>
        </Modal>
    );
};

export default BusinessEntityModalEdit;
