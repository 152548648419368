import React from "react";
import {Box, Typography} from "@mui/material";
import theme from "theme";
import AddFeaturesCard from "./add-features-card/add-features-card";
import {DataGrid, GridColDef, GridToolbar} from "@mui/x-data-grid";
import "./add-features.component.scss";
import {useGlobalContext} from "../../context/global-context";
import ButtonComponent from "../shared/button/button.component";
import {ArrowRightSymbols} from "../../assets/icons";
import {MetricType} from "./feature-types";
import {useParams} from "react-router-dom";

interface MetricData {
    id: string;
    featureName: string;
    originDataAsset: string;
    featureType: string;
    feature: MetricType; // TODO: add other types: first/last and formula
}

interface AddFeaturesComponentProps {
    handleClickFeatureClose: () => void;
}

const AddFeaturesComponent: React.FC<AddFeaturesComponentProps> = ({handleClickFeatureClose}) => {
    const {entityId} = useParams<{ entityId: string }>();
    const {isShowFeature} = useGlobalContext();
    const handleDelete = (id: MetricData["id"]) => {
        setRows((prevRows) => prevRows.filter((row) => row.id !== id));
    };
    const [rows, setRows] = React.useState<MetricData[]>([]);
    const columns: GridColDef[] = [
        {field: "featureName", headerName: "Feature name", flex: 2, sortable: true},
        {field: "originDataAsset", headerName: "Origin data asset", flex: 1, sortable: false},
        {
            field: "featureType",
            headerName: "Feature type",
            flex: 1,
            sortable: false,
            renderCell: (params) => (
                <Typography className="flex-box-align-center" sx={{color: theme.palette.customColor.purple}}>
                    <span className="material-symbols-outlined"
                          style={{fontSize: "20px", color: theme.palette.customColor.purple}}>
                        analytics
                    </span>
                    {params.value}
                </Typography>
            ),
        },
        {
            field: "deleteAction",
            headerName: "",
            width: 30,
            sortable: false,
            renderCell: (params) => (
                <span
                    className="material-symbols-outlined"
                    style={{cursor: "pointer"}}
                    onClick={() => handleDelete(params.row.id)}
                >
                    delete
                </span>
            ),
        },
    ];

    const handleFeatureAdded = (feature: MetricType) => {
        if (feature.hasOwnProperty("measures")) {
            const newRows = feature.measures?.map(measure => ({
                id: measure.name,
                featureName: measure.alias || measure.name,
                originDataAsset: feature.dataAssetId,
                featureType: "Metric",
                feature: feature
            }));
            if (newRows) setRows((prevRows) => [...prevRows, ...newRows]);
        }
    };

    const handleNext = () => {
        // TODO: Save features. <row.feature> has MetricType data about the feature created
        console.log(rows);
    };

    return (
        <Box className="flex-box-col-center"
             sx={{maxWidth: "1232px", width: "100%", margin: "0 auto", overflow: "hidden"}}>
            <Box className="flex-box-center-space-between" sx={{width: "100%", marginTop: "24px"}}>
                <Box className="flex-box-align-center" sx={{width: "230px"}}></Box>
                <Typography
                    variant="h5"
                    sx={{
                        fontSize: "18px",
                        color: theme.palette.customColor.slateGrey,
                        lineHeight: "26px",
                    }}
                >
                    Add features to entity
                </Typography>

                <Box className="flex-box-align-center" sx={{width: "230px"}}>
                    <ButtonComponent
                        variant="outlined"
                        label="Exit"
                        onClick={handleClickFeatureClose}
                        sx={{marginRight: "16px"}}
                    />

                    <ButtonComponent
                        variant="contained"
                        endIcon={<ArrowRightSymbols/>}
                        label="Add features"
                        onClick={handleNext}
                        disabled={!Boolean(rows?.length)}
                    />
                </Box>
            </Box>
            <Typography
                variant="h3"
                sx={{
                    fontSize: "42px",
                    color: theme.palette.customColor.dark,
                    lineHeight: "46px",
                    letterSpacing: "-0.84px",
                    margin: "50px 0 24px 0",
                }}
            >
                Add features
            </Typography>
            <Typography variant="subtitle2" sx={{fontWeight: 400, color: theme.palette.customColor.grey}}>
                Add features to&nbsp;
                <Typography component="span"
                            sx={{fontSize: "16px", fontWeight: 500, color: theme.palette.customColor.dark}}>
                    {entityId}
                </Typography>
            </Typography>

            <Box>
                <AddFeaturesCard onFeatureSaved={handleFeatureAdded}/>
            </Box>
            {isShowFeature && (
                <Box className="feature-table" sx={{maxWidth: "1232px", width: "100%"}}>
                    <Typography
                        variant="subtitle2"
                        sx={{
                            color: theme.palette.customColor.black,
                            fontWeight: 600,
                            marginBottom: "16px",
                        }}
                    >
                        Added features
                    </Typography>
                    <DataGrid
                        hideFooter={true}
                        rows={rows}
                        columns={columns}
                        disableColumnFilter
                        disableColumnSelector
                        disableDensitySelector
                        className="entity-data-grid feature-data-grid"
                        slots={{toolbar: GridToolbar}}
                        slotProps={{
                            toolbar: {
                                showQuickFilter: false,
                            },
                        }}
                        autoHeight
                        initialState={{
                            sorting: {
                                sortModel: [{field: "featureName", sort: "asc"}],
                            },
                        }}
                    />
                </Box>
            )}
        </Box>
    );
};

export default AddFeaturesComponent;
