import React from "react";
import {Box, Typography} from "@mui/material";
import theme from "theme";
import ButtonComponent from "../../shared/button/button.component";
import {useGlobalContext} from "../../../context/global-context";

const DataAssetsTitle: React.FC = () => {
    const {isEditMode} = useGlobalContext();

    const handleClick = () => {
        console.log("Button clicked");
    };

    return (
        <Box className="flex-box-space-between">
            <Typography variant="h2"
                        sx={{
                            fontSize: "42px",
                            color: theme.palette.customColor.dark,
                            letterSpacing: "-0.84px",
                            lineHeight: "46px"
                        }}>
                Data assets
            </Typography>

            {/* TODO: enable later on {isEditMode && <ButtonComponent onClick={handleClick} variant="contained" label="+ Add new"/>}*/}
        </Box>
    );
};

export default DataAssetsTitle;
