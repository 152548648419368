import React, {useState} from "react";
import {Box, CircularProgress, Typography} from "@mui/material";
import {ArrowLeftSymbols} from "assets/icons";
import ButtonComponent from "components/shared/button/button.component";
import AddFeaturesModalContent from "./add-features-data-content/add-features-modal-content";
import {useGlobalContext} from "context/global-context";
import {DataAssetFullResponse, getDataAssetById} from "services/data-assets";
import {DataAssetName} from "../../data-asset-name";
import theme from "theme";
import "./add-features-modal-data.scss";
import {MetricType} from "../../feature-types";

interface AddFeaturesModalDataProps {
    dataAssets: string[];
    selectedAsset: DataAssetFullResponse | null;
    setSelectedAsset: (asset: DataAssetFullResponse) => void;
    openMeasuresModal: boolean;
    setOpenMeasuresModal: (open: boolean) => void;
    handleCloseModal: () => void;
    isLoading: boolean;
    errorMessage: string | null;
    onMetricSaved: (metric: MetricType) => void;
}

const AddFeaturesMetricModalData: React.FC<AddFeaturesModalDataProps> = ({
                                                                       dataAssets,
                                                                       selectedAsset,
                                                                       setSelectedAsset,
                                                                       openMeasuresModal,
                                                                       setOpenMeasuresModal,
                                                                       handleCloseModal,
                                                                       isLoading,
                                                                             errorMessage,
                                                                             onMetricSaved
                                                                   }) => {
    const [isCreateMetricEnabled, setCreateMetricEnabled] = useState(false);
    const [metricDraft, setMetricDraft] = useState<MetricType>();

    const fetchDataAsset = async (assetId: string) => {
        try {
            const response = await getDataAssetById(assetId);
            setSelectedAsset(response);
        } catch (error: any) {
            console.log(error.message);
        }
    };

    const handleOpen = (asset: string) => {
        fetchDataAsset(asset);
    };

    const handleClose = () => {
        // setSelectedAsset(null);
        setCreateMetricEnabled(false);
    };

    const handleMetricUpdated = (metric: MetricType) => {
        setCreateMetricEnabled(Boolean(metric.measures?.length));
        setMetricDraft(metric);
        console.log(`Metric updated: ${metric.dataAssetId}`);
        console.log(metric.filters);
        console.log(metric.measures);
    };

    const saveMetric = () => {
        if (metricDraft) onMetricSaved(metricDraft);
        handleCloseModal();
    };

    if (errorMessage && !isLoading) {
        return <Typography color="error" variant="body2" sx={{margin: "auto"}}>{errorMessage}</Typography>;
    }

    if (selectedAsset && !isLoading) {
        return (
            <Box className="flex-box-col" sx={{width: "100%"}}>
                <DataAssetName asset={selectedAsset}/>

                <ButtonComponent
                    className="select-button-back"
                    variant="text"
                    label={<ArrowLeftSymbols/>}
                    onClick={handleClose}
                />

                <AddFeaturesModalContent
                    selectedAsset={selectedAsset}
                    metricDraft={metricDraft}
                    onMetricUpdated={handleMetricUpdated}
                    type="metric"
                />

                <Box className="flex-box-end" sx={{position: "absolute", bottom: 28, right: 28}}>
                    <ButtonComponent variant="text" label="Cancel" onClick={handleClose}/>
                    <ButtonComponent
                        disabled={!isCreateMetricEnabled}
                        variant="contained"
                        label="Create metric"
                        onClick={saveMetric}
                    />
                </Box>
            </Box>
        );
    }

    return (
        <Box
            className={!dataAssets.length ? "flex-box-col-center" : ""}
            sx={{width: "100%", maxHeight: "500px", overflowY: "scroll"}}
        >
            {isLoading ? (
                <CircularProgress sx={{fontColor: theme.palette.customColor.grey, margin: "auto"}}/>
            ) : (
                dataAssets.length ? (
                    dataAssets.map((asset, index) => (
                        <Box
                            className="modal-data"
                            key={index}
                            onClick={() => handleOpen(asset)}
                            sx={{cursor: "pointer"}}
                        >
                            <Typography
                                className="flex-box-align-center"
                                variant="subtitle2"
                                sx={{color: theme.palette.customColor.darkGrey}}
                            >
                            <span className="material-symbols-outlined modal-icon-data">
                                database
                            </span>
                                {asset}
                            </Typography>
                        </Box>
                    ))
                ) : (
                    <Typography variant="subtitle2">
                        No data assets found
                    </Typography>
                )
            )}
        </Box>
    );
};

export default AddFeaturesMetricModalData;
