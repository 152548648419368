import React, {useState} from "react";
import Box from '@mui/material/Box';
import {DataGrid, GridToolbar, GridColDef} from '@mui/x-data-grid';
import './business-entity-tabs-table.component.scss';
import ButtonComponent from '../../../shared/button/button.component';
import {EntityDetailResponse} from 'types/index';
import {useGlobalContext} from 'context/global-context';
import {EntityTabs} from '../business-entity-tabs.component';
import BusinessEntitiesListTabsTableSearch
    from "./business-entity-tabs-table-search/business-entity-tabs-table-search.component";
import BusinessEntityFeaturesModal
    from "../../business-entity-features-modal/business-entities-features-modal.component";

interface BusinessEntitiesTabsTableProps {
    name: string;
    data: EntityDetailResponse["dataAssets"] | { [key: string]: any }[];
    columns: GridColDef[];
}

interface EntityData {
    id: number;
    entity?: string;
    entitySec?: string;
    relationship?: string;
    featureName?: string;
    type?: string;
    timeline?: string;
    dataAssetName?: string;
    column?: string;
    relation?: string;
}

const BusinessEntityTabsTable: React.FC<BusinessEntitiesTabsTableProps> = ({data, columns, name}) => {
    const [searchText, setSearchText] = React.useState<string>("");
    const [filteredRows, setFilteredRows] = React.useState<BusinessEntitiesTabsTableProps["data"]>(data);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const {isEditMode} = useGlobalContext();

    const handleSearchTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value.toLowerCase();
        setSearchText(value);

        const filtered = data.filter(row =>
            columns.some(column =>
                String(row[column.field as keyof EntityData]).toLowerCase().includes(value)
            )
        );
        setFilteredRows(filtered);
    };

    const handleToggleModalOpen = (isOpen: boolean) => {
        setIsModalOpen(isOpen);
    };

    return (
        <Box sx={{marginBottom: "24px"}}>
            <Box className="flex-box-space-between">
                <BusinessEntitiesListTabsTableSearch value={searchText} onChange={handleSearchTextChange}/>

                {(name === EntityTabs.Features && isEditMode) &&
                    <ButtonComponent onClick={() => handleToggleModalOpen(true)} variant="contained"
                                     label="+ Add features"/>
                }
            </Box>
            <DataGrid
                hideFooter={true}
                rows={filteredRows}
                columns={columns}
                disableColumnFilter
                disableColumnSelector
                disableDensitySelector
                className={`entity-data-grid ${name}-table`}
                slots={{toolbar: GridToolbar}}
                slotProps={{
                    toolbar: {
                        showQuickFilter: false,
                    },
                }}
                initialState={{
                    sorting: {
                        sortModel: [
                            {field: "entity", sort: "asc"},
                            {field: "featureName", sort: "asc"},
                            {field: "dataAssetName", sort: "asc"},
                        ],
                    },
                }}
                autoHeight
                sx={{marginTop: "24px"}}
            />

            <BusinessEntityFeaturesModal isModalOpen={isModalOpen} onClose={() => handleToggleModalOpen(false)}/>
        </Box>
    );
};

export default BusinessEntityTabsTable;
