import React, {useState} from "react";
import {Box, Typography} from "@mui/material";
import {ArrowLeftSymbols} from "assets/icons";
import theme from "theme";
import "./add-features-modal-data.scss";
import ButtonComponent from "../../../shared/button/button.component";
import {useGlobalContext} from "../../../../context/global-context";
import AddFeaturesModalContent from "./add-features-data-content/add-features-modal-content";
import {DataAssetFullResponse, getDataAssetById} from "../../../../services/data-assets";
import {DataAssetName} from "../../data-asset-name";

interface AddFeaturesModalDataProps {
    dataAssets: string[];
    selectedAsset: DataAssetFullResponse | null;
    setSelectedAsset: (asset: DataAssetFullResponse) => void;
    openMeasuresModal: boolean;
    setOpenMeasuresModal: (open: boolean) => void;
    handleCloseModal: () => void;
}

const AddFeaturesFirstLastModalData: React.FC<AddFeaturesModalDataProps> = ({
                                                                                dataAssets,
                                                                                selectedAsset,
                                                                                setSelectedAsset,
                                                                                openMeasuresModal,
                                                                                setOpenMeasuresModal,
                                                                                handleCloseModal,
                                                                            }) => {
    const [isCreateMetricEnabled, setCreateMetricEnabled] = useState(false);
    const {isShowFeature, setIsShowFeature} = useGlobalContext();

    const fetchDataAsset = async (assetId: string) => {
        try {
            const response = await getDataAssetById(assetId);
            setSelectedAsset(response);
        } catch (error: any) {
            console.log(error.message);
        }
    };

    const handleOpen = (asset: string) => {
        fetchDataAsset(asset);
    };

    const handleClose = () => {
        setCreateMetricEnabled(false);
    };

    return (
        <Box sx={{width: "100%"}}>
            {!selectedAsset ? (
                <Box sx={{width: "100%", maxHeight: "500px", overflowY: "scroll"}}>
                    {dataAssets.map((asset, index) => (
                        <Box
                            className="modal-data"
                            key={index}
                            onClick={() => handleOpen(asset)}
                            sx={{cursor: "pointer"}}
                        >
                            <Typography
                                className="flex-box-align-center"
                                variant="subtitle2"
                                sx={{color: theme.palette.customColor.darkGrey}}
                            >
                                <span className="material-symbols-outlined modal-icon-data">database</span>
                                {asset}
                            </Typography>
                        </Box>
                    ))}
                </Box>
            ) : (
                <Box>
                    {selectedAsset && <DataAssetName asset={selectedAsset}/>}

                    <ButtonComponent
                        className="select-button-back"
                        variant="text"
                        label={<ArrowLeftSymbols/>}
                        onClick={handleClose}
                    />

                    <AddFeaturesModalContent
                        selectedAsset={selectedAsset}
                        type="first/last"
                    />

                    <Box className="flex-box-end">
                        <ButtonComponent variant="text" label="Cancel" onClick={handleClose}/>
                        <ButtonComponent
                            disabled={!isCreateMetricEnabled}
                            variant="contained"
                            label="Create first / last"
                            onClick={() => {
                                handleClose();
                                handleCloseModal();
                                setIsShowFeature(true);
                            }}
                        />
                    </Box>
                </Box>
            )}
        </Box>
    );
};

export default AddFeaturesFirstLastModalData;
