import React from "react";
import "./business-entity.scss";
import BusinessEntityComponent
    from "components/business-entity-component/business-entity.component";

const BusinessEntity = () => {
    return (
        <BusinessEntityComponent/>
    );
};

export default BusinessEntity;
