import React from "react";
import {Box, Container} from "@mui/material";
import DataAssetsTable from "./data-assets-table/data-assets-table.component";
import DataAssetsTitle from "./data-assets-title/data-assets-titile.component";
import {useGlobalContext} from "../../context/global-context";

const DataAssetsComponent: React.FC = () => {
    const {drawerOpen} = useGlobalContext();

    return (
        <Container sx={{
            height: "100%",
        }}>
            <Box
                className="flex-box-start"
                flexDirection="column"
                sx={{margin: "41px 50px 0 50px"}}
            >
                <Box sx={{width: drawerOpen ? "calc(100% - 125px)" : "100%", margin: "0 0 25px 0"}}>
                    <DataAssetsTitle/>
                    <DataAssetsTable/>
                </Box>
            </Box>
        </Container>
    );
};

export default DataAssetsComponent;
