import React from 'react';
import './onboarding.component.scss';
import OnboardingLinearStepper from "./onboarding-linear-stepper/onboarding-linear-stepper.component";

const OnboardingComponent = () => (
    <>
        <OnboardingLinearStepper/>

    </>
);

export default OnboardingComponent;
