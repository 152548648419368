import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import {FilterOptionsState, Paper} from "@mui/material";


interface OptionType {
  inputValue?: string;
  title: string;
}

type AutocompleteCreateOptionProps = {
    label?: string
    options?: OptionType[],
    onValueSelected: (value: string) => void,
    className?: string,
    inputClassName?: string,
}

const filter = createFilterOptions<OptionType>();

export default function AutocompleteCreateOption(
    {options = [], label = "", onValueSelected, className = "", inputClassName = ""}: AutocompleteCreateOptionProps
) {
  const [value, setValue] = React.useState<OptionType | null>(null);

  const handleValueSelected = (_event: React.SyntheticEvent, newValue: any) => {
    if (typeof newValue === "string") {
      setValue({
        title: newValue,
      });
    } else if (newValue && newValue.inputValue) {
      // Create a new value from the user input
      setValue({
        title: newValue.inputValue,
      });
    } else {
      setValue(newValue);
    }
      onValueSelected(newValue?.inputValue || "");
  };

  const handleFilterOptions = (options: OptionType[], params: FilterOptionsState<OptionType>) => {
    const filtered = filter(options, params);
    const { inputValue } = params;
    // Suggest the creation of a new value
    const isExisting = options.some((option) => inputValue === option.title);

    if (inputValue !== "" && !isExisting) {
      filtered.push({
        inputValue,
        title: `Add "${inputValue}"`,
      });
    }

    return filtered;
  };

  return (
    <Autocomplete
      value={value}
      onChange={handleValueSelected}
      filterOptions={handleFilterOptions}
      className={className}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      options={options}
      getOptionLabel={(option) => {
        // Value selected with enter, right from the input
        if (typeof option === "string") {
          return option;
        }
        // Add custom option created dynamically
        if (option.inputValue) {
          return option.inputValue;
        }
        // Regular option
        return option.title;
      }}
      renderOption={(props, option) => {
        const { key, ...optionProps } = props;
        return (
          <li key={key} {...optionProps}>
            {option.title}
          </li>
        );
      }}
      // PaperComponent={CustomPaper}
      // ListboxComponent={CustomPaperList}
      sx={{ width: "100%" }}
      freeSolo
      renderInput={(params) => (
          <TextField {...params} label={label} className={inputClassName}/>
      )}
    />
  );
}