import React from "react";
import {Box, Modal} from "@mui/material";
import AddFeaturesComponent from "../../add-features-component/add-features.component";

interface BusinessEntityFeaturesModalProps {
    isModalOpen: boolean;
    onClose: () => void;
}

const BusinessEntityFeaturesModal: React.FC<BusinessEntityFeaturesModalProps> = ({
                                                                                     isModalOpen,
                                                                                     onClose,
                                                                                 }) => {
    return (
        <Modal className="features-modal" open={isModalOpen} onClose={onClose}>
            <Box className="container-modal" sx={{width: "100%", padding: "20px"}}>
                <AddFeaturesComponent handleClickFeatureClose={onClose}/>
            </Box>
        </Modal>
    );
};

export default BusinessEntityFeaturesModal;
