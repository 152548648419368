import React, {useState} from "react";
import {Box, Typography} from "@mui/material";
import {DataGrid, GridToolbar, GridColDef} from "@mui/x-data-grid";
import theme from "theme";
import ButtonComponent from "../../../shared/button/button.component";

interface EntityData {
    id: number;
    nameData: string;
    key: string;
    relatedFields: string;
    entityKeyTable: string;
}

const initialRows: EntityData[] = [
    {
        id: 1,
        nameData: "networx_prod.core.bill_breakdown_dim",
        key: "timestamp",
        relatedFields: "iFiled 1",
        entityKeyTable: "bill bill bill"
    },
    {
        id: 2,
        nameData: "networx_prod.core.bill_breakdown_dim",
        key: "timestamp",
        relatedFields: "iFiled 1",
        entityKeyTable: "bill bill bill"
    },
    {
        id: 3,
        nameData: "networx_prod.core.bill_breakdown_dim",
        key: "timestamp",
        relatedFields: "iFiled 1",
        entityKeyTable: "bill bill bill"
    },
    {
        id: 4,
        nameData: "networx_prod.core.bill_breakdown_dim",
        key: "timestamp",
        relatedFields: "iFiled 1",
        entityKeyTable: "bill bill bill"
    },
    {
        id: 5,
        nameData: "networx_prod.core.bill_breakdown_dim",
        key: "timestamp",
        relatedFields: "iFiled 1",
        entityKeyTable: "bill bill bill"
    },
    {
        id: 6,
        nameData: "networx_prod.core.bill_breakdown_dim",
        key: "timestamp",
        relatedFields: "iFiled 1",
        entityKeyTable: "bill bill bill"
    },
    {
        id: 7,
        nameData: "networx_prod.core.bill_breakdown_dim",
        key: "timestamp",
        relatedFields: "iFiled 1",
        entityKeyTable: "bill bill bill"
    },
];

const AddEditBusinessEntitiesRelatedDatasetsTableSecond = () => {
    const [rows, setRows] = useState<EntityData[]>(initialRows.slice(0, 3));
    const [showAll, setShowAll] = useState<boolean>(false);
    const [deletedRows, setDeletedRows] = useState<Set<number>>(new Set());

    const handleDelete = (id: number) => {
        setRows((prevRows) => prevRows.filter((row) => row.id !== id));
        setDeletedRows((prevDeletedRows) => new Set(prevDeletedRows).add(id));
    };

    const handleClick = () => {
        if (!showAll) {
            setRows(initialRows.filter(row => !deletedRows.has(row.id)));
            setShowAll(true);
        } else {
            setRows(initialRows.filter(row => !deletedRows.has(row.id)).slice(0, 3));
            setShowAll(false);
        }
    };

    const hiddenRowCount = initialRows.filter(row => !deletedRows.has(row.id)).length - rows.length;

    const columns: GridColDef[] = [
        {field: "nameData", headerName: "Name", flex: 2, sortable: true},
        {field: "key", headerName: "Key", flex: 1, sortable: false},
        {field: "relatedFields", headerName: "Related fields", flex: 1, sortable: false},
        {field: "entityKeyTable", headerName: "Entity key table", flex: 1, sortable: false},
        {
            field: "deleteAction",
            headerName: "",
            width: 30,
            sortable: false,
            renderCell: (params) => (
                <span
                    className="material-symbols-outlined"
                    style={{cursor: "pointer"}}
                    onClick={() => handleDelete(params.row.id)}
                >
                    delete
                </span>
            ),
        },
    ];

    return (
        <Box className="flex-box-col-center" sx={{maxWidth: "1005px", width: "100%"}}>
            <Box className="dim-table-style" sx={{width: "100%", marginTop: "32px"}}>
                <Typography
                    variant="h5"
                    sx={{
                        color: theme.palette.customColor.graphiteGrey,
                        fontWeight: 400,
                        lineHeight: "24px",
                        marginBottom: "16px",
                    }}
                >
                    Suggested by Lynk:
                </Typography>

                <DataGrid
                    hideFooter={true}
                    rows={rows}
                    columns={columns}
                    disableColumnFilter
                    disableColumnSelector
                    disableDensitySelector
                    disableAutosize={false}
                    className="entity-data-grid"
                    slots={{toolbar: GridToolbar}}
                    slotProps={{
                        toolbar: {
                            showQuickFilter: false,
                        },
                    }}
                    autoHeight
                    initialState={{
                        sorting: {
                            sortModel: [{field: "nameData", sort: "asc"}],
                        },
                    }}
                />

                <ButtonComponent
                    onClick={handleClick}
                    variant="textPurple"
                    label={showAll ? "Show less" : `Show ${hiddenRowCount} more suggestions`}
                    sx={{fontSize: "14px"}}
                />
            </Box>
        </Box>
    );
};

export default AddEditBusinessEntitiesRelatedDatasetsTableSecond;
