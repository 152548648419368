import React from 'react';
import {styled} from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';

const Search = styled('div')(({theme}) => ({
    position: 'relative',
    maxWidth: '360px',
    width: '100%',
    marginBottom: '24px',
}));

const SearchIconWrapper = styled('div')(({theme}) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 999,
}));

const StyledInputBase = styled(InputBase)(({theme}) => ({
    color: theme.palette.customColor.grey,
    width: '100%',
    fontSize: '16px',
    fontWeight: 500,
    '& .MuiInputBase-input': {
        padding: '12px 16px 12px 47px',
        transition: theme.transitions.create('width'),
        borderRadius: '16px',
        backgroundColor: theme.palette.customColor.lightestGrey5,
        boxShadow: '0 4px 13px 0 rgba(97, 97, 97, 0.02)',
        '&::placeholder': {
            color: theme.palette.customColor.grey,
            fontSize: '16px',
            fontWeight: 500,
        },
    },
}));

interface DataAssetsProps {
    value: string;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const CustomTableSearch: React.FC<DataAssetsProps> = ({value, onChange}) => {
    return (
        <Search>
            <SearchIconWrapper>
                <SearchIcon/>
            </SearchIconWrapper>
            <StyledInputBase
                placeholder="Search"
                inputProps={{'aria-label': 'search'}}
                value={value}
                onChange={onChange}
            />
        </Search>
    );
};

export default CustomTableSearch;
