import React from "react";
import {Box} from "@mui/material";


const AddFeaturesFormula: React.FC = () => {
    return (
        <Box className="flex-box-col-center">

            AddFeaturesFormula
        </Box>
    );
};

export default AddFeaturesFormula;
