import React, {useState, useEffect} from "react";
import {Box, Typography, TextField, IconButton, MenuItem} from "@mui/material";
import ButtonComponent from "../../../../../shared/button/button.component";
import AddFeaturesFirstLastModal from "./add-features-first-last-modal/add-features-first-last-modal";
import SelectComponent from "../../../../../shared/select/select.component";
import theme from "theme";
import {SelectChangeEvent} from "@mui/material/Select";
import {DataAssetFullResponse} from "../../../../../../services/data-assets";

// Define SelectOption type
interface SelectOption {
    id?: string; // Optional if not used in some cases
    title: string;
    icon?: string; // Optional if not used in some cases
}

// Define the type for featuresFirstLastData
const featuresFirstLastData: SelectOption[] = [
    {title: "First"},
    {title: "Last"},
];

// Define the type for sortListData
const sortListData: SelectOption[] = [
    {id: "1", icon: "group", title: "group"},
    {id: "2", icon: "database", title: "database"},
    {id: "3", icon: "match_case", title: "match_case"},
    {id: "4", icon: "group", title: "flavor"},
    {id: "5", icon: "database", title: "sum(total_sales)"},
    {id: "6", icon: "match_case", title: "labor_time"},
    {id: "7", icon: "group", title: "ide-heartbeat"},
    {id: "8", icon: "database", title: "first"},
    {id: "9", icon: "match_case", title: "sum(total_sales)"},
    {id: "10", icon: "group", title: "group"},
    {id: "11", icon: "database", title: "database"},
    {id: "12", icon: "match_case", title: "match_case"},
    {id: "13", icon: "group", title: "flavor"},
    {id: "14", icon: "database", title: "sum(total_sales)"},
    {id: "15", icon: "group", title: "labor_time"},
];

interface FirstLastDataProps {
    id: number;
    title: string;
    showIcon?: boolean;
}

interface AddFeaturesFirstLastProps {
    selectedAsset: DataAssetFullResponse | null;
    onUpdate: () => void;
}

const AddFeaturesFirstLastConfiguration: React.FC<AddFeaturesFirstLastProps> = ({
                                                                                    selectedAsset,
                                                                                    onUpdate,
                                                                                }) => {
    const [selectedMethod, setSelectedMethod] = useState<string>("");
    const [selectedSort, setSelectedSort] = useState<string>("");
    const [open, setOpen] = useState(false);
    const [selectedFirstLastData, setSelectedFirstLastData] = useState<FirstLastDataProps[]>([]);

    const handleOpenFields = () => {
        setOpen(true);
    };

    const handleCloseModalFields = () => {
        setOpen(false);
    };

    const handleRemoveItem = (id: number) => {
        setSelectedFirstLastData((prevState) =>
            prevState.filter((item) => item.id !== id)
        );
    };

    return (
        <Box className="flex-box-col-start" sx={{margin: "32px 0"}}>
            <Typography
                variant="subtitle2"
                sx={{
                    color: theme.palette.customColor.darkGrey,
                    fontWeight: 600,
                    margin: "10px 0 12px 0",
                }}
            >
                First / Last Configuration
            </Typography>

            <Box sx={{width: "100%", marginBottom: "32px"}}>
                <Typography
                    variant="h5"
                    sx={{color: theme.palette.customColor.darkGrey, marginBottom: "10px"}}
                >
                    Method
                </Typography>

                <SelectComponent
                    value={selectedMethod}
                    onChange={(e: SelectChangeEvent<string>) => setSelectedMethod(e.target.value)}
                    options={featuresFirstLastData}
                    renderOption={(option) => (
                        <MenuItem key={option.title} value={option.title} disableRipple={true}>
                            {option.title}
                        </MenuItem>
                    )}
                    placeholder="Select method"
                />
            </Box>

            <Box sx={{width: "100%"}}>
                <Typography
                    variant="h5"
                    sx={{color: theme.palette.customColor.darkGrey, marginBottom: "10px"}}
                >
                    Sort by:
                </Typography>

                <SelectComponent
                    value={selectedSort}
                    onChange={(e: SelectChangeEvent<string>) => setSelectedSort(e.target.value)}
                    options={sortListData}
                    renderOption={(option) => (
                        <MenuItem key={option.id} value={option.title} disableRipple={true}>
                            {option.icon && (
                                <span className="material-symbols-outlined" style={{marginRight: "8px"}}>
                                    {option.icon}
                                </span>
                            )}
                            {option.title}
                        </MenuItem>
                    )}
                    placeholder="Select sort"
                    showSearch
                    paperProps={{"maxHeight": "440px", marginTop: "-200px"}}
                />
            </Box>

            <Box sx={{width: "100%", marginTop: "32px"}}>
                <Typography
                    variant="subtitle2"
                    sx={{
                        color: theme.palette.customColor.darkGrey,
                        fontWeight: 600,
                        margin: "0 0 12px 0",
                    }}
                >
                    Fields
                </Typography>

                <Box sx={{width: "100%"}}>
                    {selectedFirstLastData.map((item) => (
                        <Box
                            key={item.id}
                            className="measures-selected"
                            sx={{
                                position: "relative",
                                marginBottom: "12px",
                                "&:last-child": {
                                    marginBottom: 0,
                                },
                            }}
                        >
                            <Box className="flex-box-align-center" sx={{marginBottom: "6px"}}>
                                {item.showIcon && (
                                    <span
                                        className="material-symbols-outlined"
                                        style={{
                                            fontSize: "24px",
                                            color: theme.palette.customColor.purple,
                                            marginRight: "6px",
                                        }}
                                    >
                                        match_case
                                    </span>
                                )}
                                <Typography variant="subtitle2" sx={{color: theme.palette.customColor.dark}}>
                                    {item.title}
                                </Typography>
                            </Box>
                            <Box className="flex-box-align-center" sx={{marginLeft: "18px", padding: "6px"}}>
                                <span className="material-symbols-outlined" style={{fontSize: "16px"}}>
                                    alternate_email
                                </span>
                                <TextField
                                    className="alias-input"
                                    hiddenLabel
                                    placeholder="Enter alias.."
                                    sx={{
                                        margin: "0 0 0 5px",
                                    }}
                                />
                            </Box>
                            <IconButton
                                onClick={() => handleRemoveItem(item.id)}
                                sx={{
                                    position: "absolute",
                                    top: "4px",
                                    right: "4px",
                                    color: theme.palette.customColor.darkGrey,
                                    "&:hover": {
                                        color: theme.palette.customColor.purple,
                                    },
                                }}
                            >
                                <span className="material-symbols-outlined" style={{fontSize: "18px"}}>
                                    delete
                                </span>
                            </IconButton>
                        </Box>
                    ))}
                </Box>

                <ButtonComponent
                    onClick={handleOpenFields}
                    variant="textPurple"
                    label="+ Add field"
                    sx={{
                        fontSize: "16px",
                        fontWeight: 600,
                        margin: "12px 0 10px 0",
                        padding: 0,
                    }}
                />
                <AddFeaturesFirstLastModal
                    open={open}
                    onClose={handleCloseModalFields}
                    selectedAsset={selectedAsset}
                    selectedFirstLastDatas={selectedFirstLastData}
                    setSelectedFirstLastDatas={setSelectedFirstLastData}
                />
            </Box>
        </Box>
    );
};

export default AddFeaturesFirstLastConfiguration;
