import React from "react";
import SelectComponent from "../shared/select/select.component";
import {SelectChangeEvent} from "@mui/material/Select";
import {MenuItem} from "@mui/material";

interface OptionType {
    title: string
}
interface BooleanSelectProps {
    className?: string
    selectedOption?: OptionType["title"],
    setSelectedOption: (selectedOption: OptionType["title"]) => void
}

const BooleanSelect:React.FC<BooleanSelectProps> = ({className = "", selectedOption = "", setSelectedOption}) => {
    const options:OptionType[] = [
        {title: "True"},
        {title: "False"},
        {title: "Null"},
    ];
    return (
        <SelectComponent
            defaultValue={selectedOption}
            onChange={(e: SelectChangeEvent<string>) => setSelectedOption(e.target.value)}
            options={options}
            renderOption={(option) => (
                <MenuItem key={option.title} value={option.title} disableRipple={true}>
                    {option.title}
                </MenuItem>
            )}
            className={className}
        />
    )
}

export default BooleanSelect;