import React from "react";
import {Select, MenuItem, SelectChangeEvent, Typography} from "@mui/material";
import theme from "theme";
import "./page-size-selector.component.scss";
import {ArrowDownSymbols} from "assets/icons";

interface CustomPageSizeOptionsProps {
    pageSize: number;
    onPageSizeChange: (newPageSize: number) => void;
}

const DataAssetsPageSizeOptions: React.FC<CustomPageSizeOptionsProps> = ({pageSize, onPageSizeChange}) => {
    const availablePageSizes = [5, 10, 25];
    const defaultPageSize = 5;

    const handleChange = (event: SelectChangeEvent<number>) => {
        onPageSizeChange(event.target.value as number);
    };

    const validPageSize = availablePageSizes.includes(pageSize) ? pageSize : defaultPageSize;
    return (
        <div className="custom-select-wrapper">
            <Select
                className="select-table"
                value={validPageSize}
                onChange={handleChange}
                variant="outlined"
                sx={{
                    minWidth: "68px",
                }}
                IconComponent={ArrowDownSymbols}
                renderValue={(value: number) => (
                    <Typography variant="h5" sx={{fontSize: "16px", color: theme.palette.customColor.dark}}>
                        {value}
                    </Typography>
                )}
                MenuProps={{
                    PaperProps: {
                        className: "select-dropdown",
                        sx: {
                            "& .MuiMenuItem-root": {
                                padding: 2,
                            },
                        },
                    },
                }}
            >
                {availablePageSizes.map((size) => (
                    <MenuItem key={size} value={size}>
                        {size}
                    </MenuItem>
                ))}
            </Select>
        </div>
    );
};

export default DataAssetsPageSizeOptions;
