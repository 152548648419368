import React from "react";
import {
    useGridApiContext,
    useGridSelector,
    gridPageCountSelector,
    gridPageSelector,
    gridPageSizeSelector
} from "@mui/x-data-grid";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import "./table-pagination.component.scss"
import {Box, Typography} from "@mui/material";
import theme from "theme";
import CustomPageSizeOptions
    from "../page-size-selector/page-size-selector.component";


const CustomTablePagination: React.FC = () => {
    const apiRef = useGridApiContext();
    const page = useGridSelector(apiRef, gridPageSelector);
    const pageSize = useGridSelector(apiRef, gridPageSizeSelector);
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);

    return (
        <Box className="flex-box-space-between" sx={{flex: 1, alignItems: "center", marginTop: "27px"}}>
            <Typography
                variant="h5"
                sx={{color: theme.palette.customColor.grey, marginRight: "10px"}}
            >
                Show items:
            </Typography>
            <CustomPageSizeOptions
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => apiRef.current.setPageSize(newPageSize)}
            />
            <Pagination
                className="pagination"
                color="primary"
                variant="outlined"
                page={page + 1} // Adjust for 1-based indexing in the Pagination component
                count={pageCount}
                onChange={(event, value) => apiRef.current.setPage(value - 1)}
                renderItem={(params) => (
                    <PaginationItem
                        {...params}
                        component="h4"
                    />
                )}
            />
        </Box>

    );
}

export default CustomTablePagination;
