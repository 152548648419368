import React from "react";
import {Box, Typography} from "@mui/material";
import theme from "theme";
import SearchComponent from "../../../shared/search/search.component";
import {DataAssetFullResponse} from "../../../../services/data-assets";


interface AddFeaturesTitleSearchProps {
    searchValue: string;
    setSearchValue: (value: string) => void;
    selectedAsset: DataAssetFullResponse | null;
    title: string;
}

const AddFeaturesTitleSearch: React.FC<AddFeaturesTitleSearchProps> = ({
                                                                           searchValue,
                                                                           setSearchValue,
                                                                           selectedAsset,
                                                                           title,
                                                                       }) => {
    return (
        <Box
            className="flex-box-col-center"
            sx={{width: "100%", marginBottom: selectedAsset ? "8px" : "24px"}}
        >
            <Typography
                variant="h4"
                sx={{
                    color: theme.palette.customColor.dark,
                    fontWeight: 600,
                    marginBottom: "24px",
                }}
            >
                {selectedAsset ? title : "Add features to entity"}
            </Typography>
            {!selectedAsset && (
                <SearchComponent
                    searchValue={searchValue}
                    setSearchValue={setSearchValue}/>
            )}
        </Box>
    );
};

export default AddFeaturesTitleSearch;
