import React, {useState, useEffect} from 'react';
import {Box, Typography} from '@mui/material';
import theme from 'theme';
import './add-edit-business-entities-dim-table-column-second.component.scss';

interface Props {
    selectedValue: { value: string, label: string } | null;
}

const AddEditBusinessEntitiesDimTableColumnSecond: React.FC<Props> = ({selectedValue}) => {
    const [selectedValues, setSelectedValues] = useState<string[]>([]);

    useEffect(() => {
        if (selectedValue && !selectedValues.includes(selectedValue.label)) {
            setSelectedValues(prevValues => [...prevValues, selectedValue.label]);
        }
    }, [selectedValue]);

    const boxClassName = selectedValue ? '' : 'dim-table-column-sec';

    return (
        <Box className={boxClassName}>
            <Typography
                variant="h5"
                sx={{
                    color: selectedValue ? theme.palette.customColor.dark : theme.palette.customColor.lavenderGrey,
                    lineHeight: '22px',
                }}
            >
                {selectedValues.length > 0
                    ? selectedValues.slice(0, 3).join(' ')
                    : "Choose DIM table first"}
            </Typography>
        </Box>
    );
};

export default AddEditBusinessEntitiesDimTableColumnSecond;
