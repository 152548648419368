import React from 'react';
import {Box, Typography} from '@mui/material';
import theme from 'theme';

interface Props {
    description: string
}

const BusinessEntitiesListDescription: React.FC<Props> = ({description}) => {
    return (
        <Box margin="16px 0 24px 0">
            <Typography variant="subtitle2" sx={{ color: theme.palette.customColor.grey, fontWeight: '400' }}>
                {description}
            </Typography>
        </Box>
    );
};

export default BusinessEntitiesListDescription;
