import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import BusinessEntityTabsTable
    from './business-entity-tabs-table/business-entity-tabs-table.component';
import {EntityDetailResponse} from 'types/index';
import useLocalStorageState from '../../../page/page-localstorage';
import {GridCellParams} from '@mui/x-data-grid';
import {Chip} from '@mui/material';
import theme from 'theme';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function CustomTabPanel(props: TabPanelProps) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{py: 3}}>{children}</Box>}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

interface Props {
    relatedEntities: EntityDetailResponse['relatedEntities'];
    dataAssets: EntityDetailResponse['dataAssets'];
}

const LOCAL_STORAGE_KEY = 'drawerOpen';

export enum EntityTabs {
    Relationships = 'Relationships',
    DataAssets = 'DataAssets',
    Features = 'Features'
}

const BusinessEntityTabs: React.FC<Props> = ({relatedEntities, dataAssets}) => {
    const [value, setValue] = React.useState(0);
    const [drawerOpen] = useLocalStorageState(LOCAL_STORAGE_KEY, false);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    return (
        <Box sx={{width: drawerOpen ? 'calc(100% - 125px)' : '100%'}}>
            <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="Business entities"
                    sx={{
                        '& .MuiTab-root': {
                            minWidth: 'auto',
                            fontSize: '16px',
                            fontStyle: 'normal',
                            fontWeight: 500,
                            lineHeight: '24px',
                            marginRight: '32px',
                            padding: '16px 0',
                        },
                        '& .MuiTabs-indicator': {
                            backgroundColor: theme.palette.customColor.purple,
                        },
                        '& .Mui-selected': {
                            color: `${theme.palette.customColor.purple} !important`,
                        },
                    }}
                >
                    <Tab label="Relationships" {...a11yProps(0)} />
                    <Tab label="Features" {...a11yProps(1)} />
                    <Tab label="Data assets" {...a11yProps(2)} />
                </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
                <BusinessEntityTabsTable
                    name={EntityTabs.Relationships}
                    data={Object.entries(relatedEntities).map(([key, value], index) => ({
                        id: index,
                        entity: key,
                        relationship: value,
                    }))}
                    columns={[
                        {
                            field: 'entity',
                            headerName: 'Entity',
                            flex: 1,
                            sortable: true,
                            headerClassName: 'entity-columns--header'
                        },
                        {
                            field: 'relationship',
                            headerName: 'Relationship',
                            flex: 1,
                            sortable: false,
                            headerClassName: 'entity-columns--header'
                        },
                    ]}
                />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
                <BusinessEntityTabsTable
                    name={EntityTabs.Features}
                    data={[
                        {
                            id: 1,
                            featureName: 'team.number_of_seats',
                            entity: 'User',
                            type: 'string',
                            timeframe: 'Static (dimension)'
                        },
                        {
                            id: 2,
                            featureName: 'total_chat_interactions',
                            entity: 'User',
                            type: 'number',
                            timeframe: 'Lifetime'
                        },
                        {
                            id: 3,
                            featureName: 'total_chat_interactions',
                            entity: 'User',
                            type: 'number',
                            timeframe: 'Last 30 days (calendric)'
                        },
                        {
                            id: 4,
                            featureName: 'is_active',
                            entity: 'User',
                            type: 'number',
                            timeframe: 'First 30 days (after first chat interaction)'
                        },
                        {
                            id: 5,
                            featureName: 'team.number_of_seats',
                            entity: 'User',
                            type: 'string',
                            timeframe: 'Last 7 days (calendric)'
                        },
                        {
                            id: 6,
                            featureName: 'team.name',
                            entity: 'Team',
                            type: 'number',
                            timeframe: 'Static (dimension)'
                        },
                        {
                            id: 7,
                            featureName: 'team.total_selections',
                            entity: 'Team',
                            type: 'string',
                            timeframe: 'Static (dimension)'
                        },
                        {
                            id: 8,
                            featureName: 'total_chat_interactions',
                            entity: 'Team',
                            type: 'number',
                            timeframe: 'Daily (cumulative)'
                        }
                    ]
                    }
                    columns={[
                        {
                            field: 'featureName',
                            headerName: 'Feature name',
                            flex: 1,
                            sortable: true,
                            headerClassName: 'entity-columns--header',
                            renderCell: (params: GridCellParams) => (
                                <Chip
                                    label={String(params.value)}
                                    color="primary"
                                    variant="outlined"
                                    className="tag-cell"
                                />
                            ),
                        },
                        {
                            field: 'entity',
                            headerName: 'Entity',
                            flex: 1,
                            sortable: false,
                            headerClassName: 'entity-columns--header'
                        },
                        {
                            field: 'type',
                            headerName: 'Type',
                            flex: 1,
                            sortable: false,
                            headerClassName: 'entity-columns--header'
                        },
                        {
                            field: 'timeframe',
                            headerName: 'Timeframe',
                            flex: 1,
                            sortable: false,
                            headerClassName: 'entity-columns--header'
                        }
                    ]}
                />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
                <BusinessEntityTabsTable
                    name={EntityTabs.DataAssets}
                    data={Object.values(dataAssets).map((dataAsset, index) => ({
                        id: index,
                        columnName: dataAsset['columnName'],
                        tableId: dataAsset['tableId'],
                    }))}
                    columns={[
                        {
                            field: 'tableId',
                            headerName: 'Data asset name',
                            flex: 1,
                            sortable: true,
                            headerClassName: 'entity-columns--header',
                            renderCell: (params: GridCellParams) => (
                                <Chip
                                    label={String(params.value)}
                                    color="primary"
                                    variant="outlined"
                                    className="tag-cell"
                                />
                            ),
                        },
                        {
                            field: 'columnName',
                            headerName: 'Column name',
                            flex: 1,
                            sortable: false,
                            headerClassName: 'entity-columns--header'
                        },
                        {
                            field: 'relation',
                            headerName: 'Relation',
                            flex: 1,
                            sortable: false,
                            headerClassName: 'entity-columns--header'
                        },
                    ]}
                />
            </CustomTabPanel>
        </Box>
    );
};

export default BusinessEntityTabs;
