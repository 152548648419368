import SearchIcon from "@mui/icons-material/Search";
import React from "react";
import { styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    maxWidth: '360px',
    width: '100%',
    marginRight: '50px',
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 999,
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: theme.palette.customColor.grey,
    width: '100%',
    fontSize: '16px',
    fontWeight: 500,
    '& .MuiInputBase-input': {
        padding: '16px 16px 16px 47px',
        transition: theme.transitions.create('width'),
        borderRadius: '16px',
        backgroundColor: '#F5F5F5',
        boxShadow: '0 4px 13px 0 rgba(97, 97, 97, 0.02)',
        '&::placeholder': {
            color: theme.palette.customColor.grey,
            fontSize: '16px',
            fontWeight: 500,
        },
    },
}));

const HeaderSearch = () => {
    return (
            <Search>
                <SearchIconWrapper>
                    <SearchIcon />
                </SearchIconWrapper>
                <StyledInputBase
                    placeholder="Search"
                    inputProps={{ 'aria-label': 'search' }}
                />
            </Search>
    );
};

export default HeaderSearch;
