import React from 'react';
import {Box, Chip, Stack, Typography} from '@mui/material';
import theme from 'theme';
import './aliases.component.scss';

interface AliasesProps {
    title: string;
    labels: string[];
}

const AliasesComponent: React.FC<AliasesProps> = ({title, labels}) => {
    return (
        <Box>
            <Typography variant="subtitle2" sx={{color: theme.palette.customColor.dark, marginBottom: '10px'}}>
                {title}
            </Typography>

            <Stack direction="row" spacing={1} alignItems="center">
                {labels.map((label, index) => (
                    <Chip
                        key={index}
                        className="business-entities-user-aliases"
                        label={label}
                        color="primary"
                        variant="outlined"
                    />
                ))}
            </Stack>
        </Box>
    );
};

export default AliasesComponent;
