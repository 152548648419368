import React from "react";
import {Box} from "@mui/material";

import AddEditBusinessEntitiesStepperView, {BusinessEntitiesModalCloseProps}
    from "./add-edit-business-entities-stepper/add-edit-business-entities-stepper-view.component";

const BusinessEntityView: React.FC<BusinessEntitiesModalCloseProps> = ({handleCloseModal}) => {
    return (
        <Box
            className="flex-box-col-center"
        >
            <AddEditBusinessEntitiesStepperView handleCloseModal={handleCloseModal}/>
        </Box>

    );
};

export default BusinessEntityView;
