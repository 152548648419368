export const customPalette = {
    dark: "#1B1A1C",
    purple: "#6749F4",
    purple1: "#5F43E2",
    purpleLight: "#F7F6FE",
    white: "#FFFFFF",
    lightGrey1: "#DBDADD",
    lightGrey2: "#BCBABF",
    lightGrey3: "#E9E9E9",
    grey: "#78737D",
    darkGrey: "#3C3B3F",
    black: "#000000",
    lightestGrey: "#F7F7F7",
    softGrey: "#CAC4D0",
    slateGrey: "#524E56",
    brightGreen: "#33cf4d",
    slateBlue: "#79747E",
    lightestGrey2: "#F3F3F3",
    lightestGrey3: "#E9E9EA",
    lightestGrey4: "#39393D",
    lightestGrey5: "#F5F5F5",
    brightBlue: "#007bff",
    dimGrey: "#6F6F6F",
    graphiteGrey: "#6B6B6B",
    lavenderGrey: "#97949C",
    $mediumGrey: "#6D6D6D",
};
