import React, {useState} from "react";
import {Box, Typography} from "@mui/material";
import theme from "theme";
import {DataGrid, GridColDef, GridToolbar} from "@mui/x-data-grid";
import AddBusinessEntitiesRelatedDatasetsSelect
    from "../add-edit-business-entities-related-datasets-select/add-edit-business-entities-related-datasets-select.component";
import ButtonComponent from "../../../shared/button/button.component";

export interface EntityData {
    id: number;
    nameData?: string;
    key?: string;
    relatedFields?: string;
    entityKeyTable?: string;
}

const initialRows: EntityData[] = [];

const AddEditBusinessEntitiesRelatedDatasetsTableFirst = () => {
    const [rows, setRows] = useState<EntityData[]>(initialRows);

    const handleAddClick = () => {
        const newRow: EntityData = {
            id: rows.length + 1,
            nameData: "",
            key: "",
            relatedFields: "",
            entityKeyTable: "",
        };
        setRows([...rows, newRow]);
    };

    const handleDeleteClick = (id: number) => {
        setRows(rows.filter((row) => row.id !== id));
    };

    const columns: GridColDef[] = [
        {
            field: "nameData",
            headerName: "Name",
            flex: 1.4,
            sortable: true,
            renderCell: () => <AddBusinessEntitiesRelatedDatasetsSelect/>,
        },
        {
            field: "key",
            headerName: "Key",
            flex: 0.9,
            sortable: false,
            renderCell: () => <AddBusinessEntitiesRelatedDatasetsSelect/>,
        },
        {
            field: "relatedFields",
            headerName: "Related fields",
            flex: 0.9,
            sortable: false,
            renderCell: () => <AddBusinessEntitiesRelatedDatasetsSelect/>,
        },
        {
            field: "entityKeyTable",
            headerName: "Entity key table",
            flex: 0.9,
            sortable: false,
            renderCell: () => <AddBusinessEntitiesRelatedDatasetsSelect/>,
        },
        {
            field: "deleteAction",
            headerName: "",
            width: 30,
            sortable: false,
            renderCell: (params) => (
                <span
                    className="material-symbols-outlined"
                    style={{cursor: "pointer"}}
                    onClick={() => handleDeleteClick(params.row.id)}
                >
                    delete
                </span>
            ),
        },
    ];

    return (
        <Box className="flex-box-col-center" sx={{maxWidth: "1005px", width: "100%"}}>
            <Box className="dim-table-style" sx={{width: "100%", marginTop: "32px"}}>
                <Typography
                    variant="h5"
                    sx={{
                        color: theme.palette.customColor.graphiteGrey,
                        fontWeight: 400,
                        lineHeight: "24px",
                    }}
                >
                    Added by users:
                </Typography>
                {rows.length > 0 && (
                    <DataGrid
                        hideFooter={true}
                        rows={rows}
                        columns={columns}
                        disableColumnFilter
                        disableColumnSelector
                        disableDensitySelector
                        disableAutosize={false}
                        className="entity-data-grid"
                        sx={{
                            margin: "16px 0 0 0",
                        }}
                        slots={{toolbar: GridToolbar}}
                        slotProps={{
                            toolbar: {
                                showQuickFilter: false,
                            },
                        }}
                        autoHeight={true}
                        initialState={{
                            sorting: {
                                sortModel: [{field: "nameData", sort: "asc"}],
                            },
                        }}
                    />
                )}
                <ButtonComponent
                    onClick={handleAddClick}
                    variant="textPurple"
                    label="+ Add new"
                    sx={{fontSize: "14px", marginTop: "16px", paddingLeft: 0}}
                />
            </Box>
        </Box>
    );
};

export default AddEditBusinessEntitiesRelatedDatasetsTableFirst;
