import React from 'react';
import {Box} from '@mui/material';
import AddEditBusinessEntitiesRelatedTableFirst
    from "./add-edit-business-entities-related-table-first/add-edit-business-entities-related-table-first.component";
import AddEditBusinessEntitiesRelatedTableSecond from './add-edit-business-entities-related-table-second/add-edit-business-entities-related-table-second.component';


const AddEditBusinessEntitiesRelated = () => {
    return (
        <Box className="flex-box-col-center" sx={{maxWidth: '620px', width: '100%'}}>
            <AddEditBusinessEntitiesRelatedTableFirst />

            <AddEditBusinessEntitiesRelatedTableSecond />
        </Box>
    )
};

export default AddEditBusinessEntitiesRelated;
