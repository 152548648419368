import React, {useEffect, useState} from "react";
import {Box, Container, Typography} from "@mui/material";

import theme from "theme";
import BusinessEntitiesListDescription
    from "./business-entity-description/business-entity-description.component";
import BusinessEntitiesListAliases from "./business-entity-aliases/business-entity-aliases.component";
import BusinessEntitiesTabs from "./business-entity-tabs/business-entity-tabs.component";
import BusinessEntitiesListBreadcrumbs
    from "./business-entity-breadcrumbs/business-entity-breadcrumbs.component";
import {getEntity as getEntityApi} from "../../services/entities";
import {EntityDetailResponse} from "types";
import {useParams} from "react-router-dom";
import {useGlobalContext} from "../../context/global-context";
import {InfoIcon} from "lucide-react";
import {handleRequestError} from "../../utils/axios";
import LoadingSpinner from "../shared/LoadingSpinner";

const BusinessEntityComponent = () => {
    const {entityId} = useParams<{ entityId: string }>();
    const {selectedGitBranch, isEditMode} = useGlobalContext();
    const [entity, setEntity] = useState<EntityDetailResponse | null>(null);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const {drawerOpen} = useGlobalContext();

    useEffect(() => {
        const getEntity = async () => {
            setIsLoading(true);
            setErrorMessage(null);

            try {
                const data = await getEntityApi(entityId || "", isEditMode ? selectedGitBranch : null);
                setEntity(data);
            } catch (error: any) {
                const message = handleRequestError(error);
                if (message) setErrorMessage(message);
                setEntity(null);
            } finally {
                setIsLoading(false);
            }
        };
        if (!entity) getEntity();
    }, [entity]);

    useEffect(() => {
        if (entity) document.getElementsByTagName("title")[0].text = `${entity.name} - Lynk Studio`
    }, [entity]);


    return (
        <Container>
            <Box
                className="flex-box-start"
                flexDirection="column"
                sx={{height: "100%", margin: "32px 50px 0 50px"}}
            >

                <BusinessEntitiesListBreadcrumbs entityName={entity?.name || entityId || ""}/>

                {errorMessage && !entity && (
                    <Box className="flex-box-row" margin="20px 0">
                        <InfoIcon/>
                        <Typography variant="subtitle2" paddingLeft="10px">
                            We couldn't load entity due to an error
                        </Typography>
                    </Box>
                )}

                {Boolean(entity) && (
                    <Box sx={{width: drawerOpen ? "calc(100% - 125px)" : "100%",}}>
                        <Box margin="32px 0 25px 0">
                            <Typography variant="h2"
                                        sx={{
                                            fontSize: "42",
                                            color: theme.palette.customColor.dark,
                                            letterSpacing: "-0.84px",
                                            lineHeight: "46px",
                                            textTransform: "capitalize"
                                        }}>
                                {entity?.name}
                            </Typography>
                            {entity?.description.length &&
                                <BusinessEntitiesListDescription description={entity?.description}/>}
                            {entity?.aliases && <BusinessEntitiesListAliases aliases={entity?.aliases}/>}
                        </Box>
                        <BusinessEntitiesTabs relatedEntities={entity?.relatedEntities || {}}
                                              dataAssets={entity?.dataAssets || []}/>
                    </Box>
                )}

                {(entity && isLoading && errorMessage) === false && (
                    <Box className="flex-box-row" margin="20px 0">
                        <InfoIcon/>
                        <Typography variant="subtitle2" paddingLeft="10px">No entity information found</Typography>
                    </Box>
                )}
            </Box>
            <LoadingSpinner open={isLoading}/>
        </Container>
    );
};

export default BusinessEntityComponent;
