import React from "react";
import {Container} from "@mui/material";
import Header from "components/header/header.component";
import {Outlet} from "react-router-dom";
import {useGlobalContext} from "../../context/global-context";
import BusinessEntitiesEditMode
    from "../../components/business-entities-component/business-entities-edit-mode/business-entities-edit-mode.component";


const Layout = () => {
    const {isEditMode, drawerOpen} = useGlobalContext();
    return (
        <>
            {isEditMode && <BusinessEntitiesEditMode/>}
            <Container
                sx={{
                    marginTop: isEditMode ? "40px" : "10px",
                    marginLeft: drawerOpen ? "125px" : "10px",
                }}
            >
                <Header/>
                <Outlet/>
            </Container>
        </>
    );
};

export default Layout;
