import React, {useState, useEffect} from "react";
import {
    Box, Typography, TextField, FormControl, Button, TextareaAutosize, Autocomplete
} from "@mui/material";
import {useForm, Controller} from "react-hook-form";
import theme from "theme";
import {ArrowDownSymbols} from "assets/icons";
import {connectGit} from "services/integrations";
import {GitStepFormValues} from "../onboarding-linear-stepper/onboarding-linear-stepper.component";
import PasswordField from "components/shared/form/input-password.component";
import LoadingSpinner from "components/shared/LoadingSpinner";
import {handleRequestError} from "../../../utils/axios";

interface FormField {
    id: number;
    label: string;
    placeholder: string;
    required: boolean;
    name: keyof GitStepFormValues;
    default?: string;
}

interface OnboardingStepConnectProps {
    handleNext: () => void;
    handleFormValues: (values: GitStepFormValues) => void;
    stepFormValues: GitStepFormValues | null;
}

const Options = {
    token: "Username/Token",
    ssh: "SSH Key"
};

const OnboardingStepConnectGit: React.FC<OnboardingStepConnectProps> = ({
                                                                            handleNext,
                                                                            handleFormValues,
                                                                            stepFormValues
                                                                        }) => {
    const [credType, setCredType] = useState<string>(Options.token);
    const [formError, setFormError] = useState<string | null>();
    const [isLoading, toggleLoadingState] = useState<boolean>(false);
    const [formFields, setFormFields] = useState<FormField[]>([]);
    const {
        register,
        handleSubmit,
        control,
        formState: {errors},
    } = useForm();

    useEffect(() => {
        let fields: FormField[] = [];

        if (credType === Options.token) {
            fields = [
                {
                    id: 1,
                    label: "Username",
                    placeholder: "Username",
                    required: false,
                    name: "username",
                },
                {
                    id: 2,
                    label: "Token",
                    placeholder: "Token",
                    required: true,
                    name: "token",
                },
                {
                    id: 3,
                    label: "Repo",
                    placeholder: "Repo",
                    required: true,
                    name: "repo",
                }
            ];
        } else if (credType === Options.ssh) {
            fields = [
                {
                    id: 1,
                    label: "Repo",
                    placeholder: "Repo",
                    required: true,
                    name: "repo",
                },
                {
                    id: 2,
                    label: "SSH Key",
                    placeholder: "SSH Key",
                    required: true,
                    name: "ssh",
                }
            ];
        }
        setFormFields(fields);
    }, [credType]);

    const onSubmit = async (data: any) => {
        try {
            const values: GitStepFormValues = {
                username: data.username,
                token: data.token,
                repo: data.repo,
                ssh: data.ssh,
            }
            handleFormValues(values);
            toggleLoadingState(true);
            await connectGit({
                authType: credType === Options.token ? "https" : "ssh",
                repoUrl: data.repo,
                authCred: credType === Options.token ? data.token : data.ssh,
                ...(data.username && {username: data.username}),
            });
            handleNext();
        } catch (error: any) {
            const message = handleRequestError(error)
            if (message) setFormError(message);
        } finally {
            toggleLoadingState(false);
        }
    };

    return (
        <Box>
            <Box
                className="onboarding-step-connect-box flex-box-col"
                justifyContent="flex-start"
                p={5}
            >
                <Typography variant="h2" mb="32px">Connect Git Repository</Typography>

                <Box
                    className="flex-box-col"
                    component="form"
                    onSubmit={handleSubmit(onSubmit)}
                    sx={{gap: 4}}
                >
                    <Box>
                        <Autocomplete
                            popupIcon={<ArrowDownSymbols/>}
                            value={credType}
                            defaultValue={stepFormValues?.token ? Options.token : Options.ssh}
                            onChange={(event: any, newValue: string) => {
                                setCredType(newValue);
                            }}
                            options={Object.values(Options)}
                            renderInput={(params) => <TextField {...params} label=""/>}
                            sx={{
                                "& .MuiOutlinedInput-root .MuiAutocomplete-input": {border: 0}
                            }}
                            disableClearable={true}
                        />
                    </Box>

                    {formFields.map(formField => (
                        <FormControl key={formField.id}>
                            <Typography variant="h5"
                                        lineHeight="22px"
                                        mb="10px"
                                        sx={{color: theme.palette.customColor.darkGrey}}>
                                {formField.label}
                            </Typography>
                            <Controller
                                name={formField.name}
                                control={control}
                                render={({field}) =>
                                    formField.name === "ssh" ? (
                                        <TextField
                                            {...register(formField.name, {required: formField.required})}
                                            placeholder={formField.placeholder}
                                            defaultValue={stepFormValues ? stepFormValues[formField.name] : null}
                                            error={!!errors[formField.name]}
                                            helperText={errors[formField.name] ? `${formField.label} is required` : ""}
                                            InputProps={{
                                                inputComponent: TextareaAutosize,
                                                inputProps: {
                                                    className: "text-area-connect-git",
                                                    minRows: 4
                                                }
                                            }}
                                        />
                                    ) : formField.name === "token" ? (
                                        <PasswordField
                                            register={register}
                                            required={formField.required}
                                            name={formField.name}
                                            placeholder={formField.placeholder}
                                            defaultValue={stepFormValues ? stepFormValues[formField.name] : ""}
                                            error={!!errors[formField.name]}
                                            helperText={errors[formField.name] ? `${formField.label} is required` : ""}
                                        />
                                    ) : (
                                        <TextField
                                            {...register(formField.name, {required: formField.required})}
                                            placeholder={formField.placeholder}
                                            type="text"
                                            defaultValue={stepFormValues ? stepFormValues[formField.name] : ""}
                                            error={!!errors[formField.name]}
                                            helperText={errors[formField.name] ? `${formField.label} is required` : ""}
                                        />
                                    )
                                }
                            />
                        </FormControl>
                    ))}

                    {formError && (
                        <Typography color="error" variant="body2">
                            {formError}
                        </Typography>
                    )}

                    <Box className="flex-box-end" width="100%" marginTop="40px">
                        <Button type="submit" variant="contained" sx={{padding: "12px 16px"}}>
                            Test connection
                        </Button>
                    </Box>
                </Box>
            </Box>
            <LoadingSpinner open={isLoading}/>
        </Box>
    );
};

export default OnboardingStepConnectGit;
