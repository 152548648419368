import React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import {Box} from "@mui/material";
import {ArrowDownSymbols} from "assets/icons";
import "./add-edit-business-entities-related-datasets-select.component.scss";

const billBreakdownData = [
    {label: "bill_breakdown_dim1"},
    {label: "bill_breakdown_dim2"},
    {label: "bill_breakdown_dim3"},
    {label: "bill_breakdown_dim4"},
    {label: "bill_breakdown_dim5"},
];

const AddEditBusinessEntitiesRelatedDatasetsSelect = () => {
    return (
        <Box className="autocomplete-container" sx={{width: "100%", padding: 0}}>
            <Autocomplete
                popupIcon={<ArrowDownSymbols/>}
                options={billBreakdownData}
                getOptionLabel={(option) => option.label}
                disableClearable
                ListboxProps={{
                    style: {
                        maxHeight: "344px",
                    }
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        className="autocomplete-select"
                        placeholder="Choose"
                    />
                )}
                renderOption={(props, option) => (
                    <li {...props} className="MuiAutocomplete-option MuiAutocomplete-option-custom">
                        {option.label}
                        <span className="material-symbols-outlined check-icon">check</span>
                    </li>
                )}
            />
        </Box>
    );
};

export default AddEditBusinessEntitiesRelatedDatasetsSelect;
