import React, {useEffect, useState} from "react";
import {Box, Container, Grid, Typography} from "@mui/material";
import {InfoIcon} from "lucide-react";
import theme from "theme";
import {useGlobalContext} from "context/global-context";
import {getEntities as getEntitiesApi} from "services/entities";
import {EntityResponse} from "types/index";
import BusinessEntitiesInfo from "./business-entities-info/business-entities-info.component";
import ButtonComponent from "../shared/button/button.component";
import BusinessEntitiesModalViewComponent from "./business-entities-modal/business-entities-modal-view.component";
import BusinessEntitiesModalEditComponent from "./business-entities-modal/business-entities-modal-edit.component";
import BusinessEntityFeaturesModal
    from "../business-entity-component/business-entity-features-modal/business-entities-features-modal.component";

import LoadingSpinner from "components/shared/LoadingSpinner";
import {handleRequestError} from "../../utils/axios";


const BusinessEntitiesComponent: React.FC = () => {
    const {selectedGitBranch, setSelectedGitBranch} = useGlobalContext();
    const {isEditMode, toggleEditMode} = useGlobalContext();
    const [entities, setEntities] = useState<EntityResponse[]>([]);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [isActive, setIsActive] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [openModalEdit, setOpenModalEdit] = useState(false);
    const [isLoading, toggleLoadingState] = useState<boolean>(false);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

    const {drawerOpen} = useGlobalContext();
    const handleClickFeature = () => setIsModalOpen(true);

    const handleOpenModal = () => setOpenModal(true);
    const handleCloseModal = () => {
        setOpenModal(false);
        setOpenModalEdit(false);
    };
    const handleOpenModalEdit = () => setOpenModalEdit(true);
    const handleChange = () => setIsActive(!isActive);
    const handleClickFeatureClose = () => setIsModalOpen(false);

    useEffect(() => {
        const getEntities = async () => {
            setErrorMessage(null);
            toggleLoadingState(true);

            try {
                const data = await getEntitiesApi(isEditMode ? selectedGitBranch : null);
                setEntities(data);
            } catch (error: any) {
                const message = handleRequestError(error);
                if (message) setErrorMessage(error.message);
                setEntities([]);
            } finally {
                toggleLoadingState(false);
            }
        };
        getEntities();
    }, [selectedGitBranch, isEditMode]);

    return (
        <Container sx={{position: "relative"}}>
            <Box className="flex-box-col-center" sx={{width: drawerOpen ? "calc(100% - 125px)" : "100%"}}>
                <Box className="flex-box-col-center" sx={{marginTop: "50px"}}>
                    <Typography variant="h3" sx={{color: theme.palette.customColor.dark, marginBottom: "40px"}}>
                        {isEditMode ? "Edit Business Entities" : "Explore Business Entities"}
                    </Typography>
                    <ButtonComponent onClick={handleClickFeature} variant="contained" label="+ Add features"/>

                    {isEditMode && (
                        <ButtonComponent onClick={handleOpenModal} variant="contained" label="+ Add"/>
                    )}
                </Box>

                {errorMessage && !entities.length && (
                    <Box className="flex-box-align-center" margin="20px 0">
                        <InfoIcon/>
                        <Typography variant="subtitle2" paddingLeft="10px">
                            We couldn't load entities due to an error
                        </Typography>
                    </Box>
                )}

                {entities.length ? (
                    <Grid container spacing={3} columns={11}
                          sx={{width: "calc(100% - 125px)", justifyContent: "center", margin: "36px 0 60px 0"}}>
                        {entities.map((item, index) => (
                            <Grid item key={index} xs={3} sm={3} md={3} lg={3}>
                                <BusinessEntitiesInfo
                                    name={item.name}
                                    description={item.description}
                                    isEditMode={isEditMode}
                                    onClickEdit={handleOpenModalEdit}
                                    handleChange={handleChange}
                                />
                            </Grid>
                        ))}
                    </Grid>
                ) : !errorMessage && (
                    <Box className="flex-box-align-center" margin="20px 0">
                        <InfoIcon/>
                        <Typography variant="subtitle2" paddingLeft="10px">No entities found</Typography>
                    </Box>
                )}

                <Box className="flex-box-col-center" sx={{margin: "32px auto 0 auto"}}>
                    <BusinessEntitiesModalViewComponent open={openModal} onClose={handleCloseModal}/>
                    <BusinessEntitiesModalEditComponent openEdit={openModalEdit} onClose={handleCloseModal}/>
                </Box>
            </Box>

            <BusinessEntityFeaturesModal isModalOpen={isModalOpen}
                                         onClose={handleClickFeatureClose}/>

            <LoadingSpinner open={isLoading}/>

        </Container>
    );
};

export default BusinessEntitiesComponent;
