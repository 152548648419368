import {createTheme} from "@mui/material/styles";
import {customPalette} from "./custom-palette-color";
import {ArrowDownSymbols} from "./assets/icons";

declare module "@mui/material/Button" {
    interface ButtonPropsVariantOverrides {
        textPurple: true;
        containedGrey?: true;
    }
}

declare module "@mui/material/styles" {
    interface Palette {
        customColor: typeof customPalette;
    }

    interface PaletteOptions {
        customColor?: typeof customPalette;
    }
}

const theme = createTheme({
    typography: {
        fontFamily: [
            "BlinkMacSystemFont Medium",
            "BlinkMacSystemFont Semibold",
            "BlinkMacSystemFont",
            "-apple-system",
            "sans-serif",
        ].join(","),
        h1: {
            fontSize: 80,
            fontStyle: "normal",
            fontWeight: 500,
            lineHeight: "82px",
            letterSpacing: "-1.6px",
        },
        h2: {
            fontSize: 36,
            fontStyle: "normal",
            fontWeight: 500,
            lineHeight: "40px",
            letterSpacing: "-0.72px",
        },
        h3: {
            fontSize: 48,
            fontStyle: "normal",
            fontWeight: 500,
            lineHeight: "54px",
            letterSpacing: "-0.96px",
        },
        h4: {
            fontSize: 28,
            fontStyle: "normal",
            fontWeight: 500,
            lineHeight: "32px",
            letterSpacing: "-0.28px",
        },
        h5: {
            fontSize: 14,
            fontStyle: "normal",
            fontWeight: 500,
            lineHeight: "22px",
        },
        h6: {
            color: "#524E56",
            fontSize: 13,
            fontStyle: "normal",
            fontWeight: 600,
            lineHeight: "19px",
            letterSpacing: "0.351px",
        },
        subtitle1: {
            fontSize: 24,
            fontStyle: "normal",
            fontWeight: 500,
            lineHeight: "28px",
            letterSpacing: "-0.24px",
        },
        subtitle2: {
            fontSize: 16,
            fontStyle: "normal",
            fontWeight: 500,
            lineHeight: "24px",
        },
        body1: {
            fontSize: 12,
            fontStyle: "normal",
            fontWeight: 500,
        },
        button: {
            textTransform: "none",
        },
    },
    palette: {
        primary: {
            main: customPalette.purple,
        },
        customColor: customPalette,
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 720,
            md: 720,
            lg: 1920,
            xl: 1920,
        },
    },
    components: {
        MuiTable: {
            styleOverrides: {
                root: {
                    border: "none",
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                root: (props: { theme: any }) => ({
                    color: theme.palette.customColor.grey,
                    fontSize: "16px",
                    fontWeight: 500,
                    width: "100%",
                }),
                input: (props: { theme: any }) => ({
                    padding: "12px 16px 12px 47px",
                    borderRadius: "16px",
                    backgroundColor: theme.palette.customColor.lightestGrey5,
                    boxShadow: "0 4px 13px 0 rgba(97, 97, 97, 0.02)",
                    "&::placeholder": {
                        color: theme.palette.customColor.grey,
                        fontSize: "16px",
                        fontWeight: 500,
                    },
                }),
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    paddingRight: 0,
                },
                input: (props: { theme: any }) => ({
                    border: `1px solid ${props.theme.palette.customColor.lightGrey1}`,
                    borderRadius: "16px",
                    background: props.theme.palette.customColor.white,
                    boxShadow: "0 4px 13px 0px rgba(97, 97, 97, 0.02)",
                    color: props.theme.palette.customColor.dark,
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: 500,
                    lineHeight: "24px",
                    padding: "16px",
                    "&::placeholder": {
                        color: props.theme.palette.customColor.grey,
                        opacity: 1,
                    },
                    "&:focus": {
                        border: `1px solid ${props.theme.palette.customColor.purple}`,
                    },
                }),
            },
        },
        MuiAutocomplete: {
            styleOverrides: {
                paper: {
                    boxShadow: "none",
                    marginTop: "12px",
                },
                listbox: (props: { theme: any }) => ({
                    border: `1px solid ${props.theme.palette.customColor.lightGrey1}`,
                    borderRadius: "16px",
                    background: props.theme.palette.customColor.white,
                    boxShadow: "0 4px 13px 0px rgba(97, 97, 97, 0.02)",
                    padding: "8px 5px",
                    ".MuiAutocomplete-option": {
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: 500,
                        lineHeight: "24px",
                        padding: "10px 12px",
                    },
                    ".MuiAutocomplete-option.Mui-focused": {
                        borderRadius: "16px",
                        backgroundColor: props.theme.palette.customColor.purpleLight,
                    },
                    ".MuiAutocomplete-option[aria-selected='true']": {
                        borderRadius: "16px",
                        backgroundColor: props.theme.palette.customColor.purpleLight,
                    },
                    ".MuiAutocomplete-option[aria-selected='true'].Mui-focused": {
                        borderRadius: "16px",
                        backgroundColor: props.theme.palette.customColor.purpleLight,
                    }
                }),
                root: (props: { theme: any }) => ({
                    border: `1px solid ${props.theme.palette.customColor.lightGrey1}`,
                    borderRadius: "16px",
                    boxShadow: "none",
                    "&:focus": {
                        border: `1px solid ${props.theme.palette.customColor.purple}`,
                    },
                }),
                input: {
                    border: 0,
                },
            },
        },
        MuiSelect: {
            styleOverrides: {
                select: (props: { theme: any }) => ({
                    borderRadius: "16px",
                    "&[aria-expanded='true']": {
                        border: `2px solid ${props.theme.palette.customColor.purple}`,
                    },
                    "&[aria-expanded='false']": {
                        border: `1px solid ${props.theme.palette.customColor.lightGrey1}`,
                    },
                }),
            },
        },
        MuiContainer: {
            styleOverrides: {
                root: (props: { theme: any }) => ({
                    maxWidth: "100%",
                    borderRadius: "20px 0 0 0",
                    backgroundColor: props.theme.palette.customColor.white,
                    marginTop: "10px",
                    transition: "margin 0.3s",
                    padding: "0 !important",
                }),
                maxWidthMd: {
                    maxWidth: 600,
                    "@media (min-width:720px)": {
                        maxWidth: 600,
                    },
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: (props: { theme: any }) => ({
                    borderRadius: "12px",
                    color: props.theme.palette.customColor.dark,
                    fontSize: "16px",
                    fontWeight: 600,
                    lineHeight: "24px",
                    padding: "12px 16px",
                }),
                text: (props: { theme: any }) => ({
                    border: 0,
                    "&:hover": {
                        border: 0,
                        backgroundColor: "transparent",
                    },
                    "&:disabled": {
                        color: props.theme.palette.customColor.lavenderGrey,
                    },
                }),
                contained: (props: { theme: any }) => ({
                    color: props.theme.palette.customColor.white,
                    background: props.theme.palette.customColor.purple,
                    boxShadow: "0 4px 14px 0 rgba(103, 73, 244, 0.27)",
                    "&:active, &:hover": {
                        background: props.theme.palette.customColor.purple1,
                    },
                    "&:focused": {
                        background: props.theme.palette.customColor.purple,
                        boxShadow: "none",
                    },
                    "&:disabled": {
                        background: props.theme.palette.customColor.lightGrey2,
                        color: props.theme.palette.customColor.white,
                    },
                }),
                outlined: (props: { theme: any }) => ({
                    background: props.theme.palette.customColor.white,
                    border: `1px solid ${props.theme.palette.customColor.lightGrey1}`,
                    boxShadow: "0 4px 13px 0px rgba(97, 97, 97, 0.02)",
                    "&:active, &:hover": {
                        background: props.theme.palette.customColor.white,
                        border: `1px solid ${props.theme.palette.customColor.lightGrey1}`,
                        boxShadow: "0 4px 13px 0 rgba(97, 97, 97, 0.06)",
                    },
                    "&:focused": {
                        border: `3px solid ${props.theme.palette.customColor.lightGrey1}`,
                    },
                    "&:disabled": {
                        boxShadow: "0 4px 13px 0px rgba(97, 97, 97, 0.02)",
                        background: props.theme.palette.customColor.white,
                        color: props.theme.palette.customColor.grey,
                    },
                }),
            },
            variants: [
                {
                    props: {variant: "textPurple"},
                    style: {
                        color: customPalette.purple,
                        fontSize: "14px",
                        "&:hover": {
                            background: "transparent",
                            textDecorationLine: "underline",
                        },
                        "&:disabled": {
                            color: customPalette.purple,
                            opacity: .5,
                        },
                    },
                },
                {
                    props: {variant: "containedGrey"},
                    style: {
                        color: customPalette.dark,
                        background: customPalette.lightestGrey5,
                        boxShadow: "0 4px 13px 0 rgba(97, 97, 97, 0.02)",
                        "&:hover": {
                            background: customPalette.lightGrey3,
                        },
                        "&:focused": {
                            border: "3px solid #DBDADD",
                            background: customPalette.lightestGrey5,
                        },
                        "&:disabled": {
                            background: customPalette.lightestGrey5,
                        },
                    },
                },
            ],
        },
    },
});

export default theme;
