import React, {useState} from 'react';
import {Box, Typography} from '@mui/material';
import {DataGrid, GridToolbar, GridColDef} from '@mui/x-data-grid';
import theme from 'theme';
import './add-edit-business-entities-dim-table.component.scss';
import AddEditBusinessEntitiesDimTableColumnSelect
    from './add-edit-business-entities-dim-table-column-select/add-edit-business-entities-dim-table-column-select.component';
import AddEditBusinessEntitiesDimTableColumnSecond
    from './add-edit-business-entities-dim-table-column-second/add-edit-business-entities-dim-table-column-second.component';
import AddEditBusinessEntitiesDimTableInfo
    from './add-edit-business-entities-dim-table-info/add-edit-business-entities-dim-table-info.component';

interface EntityData {
    id: number;
    dimTable?: string;
    columnDim?: string;
}

const rows: EntityData[] = [
    {id: 1, dimTable: '', columnDim: ''},
];

interface AddEditBusinessEntitiesDimTableProps {
    showDimTableInfo: boolean;
}

const AddEditBusinessEntitiesDimTable: React.FC<AddEditBusinessEntitiesDimTableProps> = ({showDimTableInfo}) => {
    const [selectedValue, setSelectedValue] = useState<{ value: string, label: string } | null>(null);
    const [inputValue, setInputValue] = useState<string>('');

    const handleValueChange = (event: any, newValue: { value: string, label: string } | null) => {
        setSelectedValue(newValue);
    };

    const handleInputValueChange = (event: any, newInputValue: string) => {
        setInputValue(newInputValue);
    };

    const columns: GridColDef[] = [
        {
            field: 'dimTable',
            headerName: 'DIM Table',
            flex: 1,
            sortable: true,
            renderCell: () => (
                <AddEditBusinessEntitiesDimTableColumnSelect
                    value={selectedValue}
                    inputValue={inputValue}
                    onChange={handleValueChange}
                    onInputChange={handleInputValueChange}
                />
            ),
        },
        {
            field: 'columnDim',
            headerName: 'Column DIM',
            flex: 1,
            sortable: false,
            renderCell: () => <AddEditBusinessEntitiesDimTableColumnSecond selectedValue={selectedValue}/>,
        },
    ];

    return (
        <Box className="flex-box-col-center" sx={{maxWidth: '620px', width: '100%'}}>
            {showDimTableInfo && <AddEditBusinessEntitiesDimTableInfo/>}

            <Box className="dim-table-style" sx={{width: '100%', marginTop: '32px'}}>
                <Typography
                    variant="h5"
                    sx={{
                        color: theme.palette.customColor.graphiteGrey,
                        fontWeight: 400,
                        lineHeight: '24px',
                        marginBottom: '16px',
                    }}
                >
                    Added by users:
                </Typography>
                <Box>
                    <DataGrid
                        hideFooter={true}
                        rows={rows}
                        columns={columns}
                        disableColumnFilter
                        disableColumnSelector
                        disableDensitySelector
                        disableAutosize={false}
                        className="entity-data-grid"
                        slots={{toolbar: GridToolbar}}
                        slotProps={{
                            toolbar: {
                                showQuickFilter: false,
                            },
                        }}
                        autoHeight
                        initialState={{
                            sorting: {
                                sortModel: [{field: 'dimTable', sort: 'asc'}],
                            },
                        }}
                        sx={{fontSize: '14px'}}
                    />
                </Box>
            </Box>
        </Box>
    );
};

export default AddEditBusinessEntitiesDimTable;
