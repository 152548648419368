import React, {useEffect, useState} from "react";
import {Box, IconButton, Popover, Typography} from "@mui/material";
import theme from "theme";
import ButtonComponent from "../../../../../shared/button/button.component";
import "./add-features-filter.scss";
import {DataAssetColumn} from "../../../../../../services/data-assets";
import AutocompleteCreateOption from "components/shared/form/autocomplete-creatable.component";
import {MetricType} from "../../../../feature-types";
import SearchComponent from "../../../../../shared/search/search.component";
import BooleanSelect from "../../../../boolean-select.component";

interface FilterItem {
    id: string,
    icon: string,
    title: string,
    type: FilterType,
    operator: string,
    value?: string,
}

type FilterType = "string" | "number" | "datetime" | "boolean";

interface FilterCondition {
    title: string,
    component?: React.ComponentType<any>,
}

interface AddFeaturesFilterProps {
    assetColumns: DataAssetColumn[],
    selectedFilters?: MetricType["filters"],
    onFiltersUpdated: (filters: MetricType["filters"]) => void,
}

const AddFeaturesFilter: React.FC<AddFeaturesFilterProps> = ({
                                                                 assetColumns,
                                                                 selectedFilters,
                                                                 onFiltersUpdated
                                                             }) => {
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [searchValue, setSearchValue] = useState<string>("");
    const [addedFilters, setAddedFilters] = useState<FilterItem[]>([]);
    const [booleanAnchorEl, setBooleanAnchorEl] = useState<HTMLDivElement | null>(null);
    const [filterItems, setFilterItems] = useState<FilterItem[]>([]);
    const filterConditionComponents: Record<FilterType, { [key: string]: FilterCondition }> = {
        "string": {
            "Is": {title: "Is", component: AutocompleteCreateOption},
            "Is not": {title: "Is not", component: AutocompleteCreateOption},
            "Is set": {title: "Is set"},
            "Is not set": {title: "Is not set"}
        },
        "number": {
            "Is": {title: "Is", component: AutocompleteCreateOption},
            "Is not": {title: "Is not", component: AutocompleteCreateOption},
            "Greater then": {title: "Greater then", component: AutocompleteCreateOption},
            "Lower then": {title: "Lower then", component: AutocompleteCreateOption},
            "Between": {title: "Between", component: AutocompleteCreateOption},
            "Is set": {title: "Is set"},
            "Is not set": {title: "Is not set"},
        },
        "boolean": {
            "Is": {title: "Is", component: BooleanSelect},
            "Is not": {title: "Is not", component: BooleanSelect},
        },
        "datetime": {
            "Before": {title: "Before", component: AutocompleteCreateOption},
            "After": {title: "After", component: AutocompleteCreateOption},
            "Between": {title: "Between", component: AutocompleteCreateOption},
            "Is set": {title: "Is set"},
            "Is not set": {title: "Is not set"},
        }
    };
    const defaultFilterOperator = "Is";

    const handleOpenFilter = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    }
    const handleClose = () => {
        setAnchorEl(null);
        setBooleanAnchorEl(null);
    };

    const handleAddFilter = (item: FilterItem) => {
        setAddedFilters((prev) => [...prev, item]);
        handleClose();
    };

    const handleRemoveItem = (removedFilterItemIndex: number) => {
        // TODO Olha: fix issue with items removal by index
        setAddedFilters((prev) => prev.splice(removedFilterItemIndex, 1));
    };

    const handleOpenBooleanPopover = (event: React.MouseEvent<HTMLDivElement>, type: FilterType) => {
        setBooleanAnchorEl(event.currentTarget);
    };

    const handleOperatorChanged = (newFilterItemIndex: number, operator = "", value = "") => {
        setAddedFilters((prev) =>
            prev.map((filterItem, index) => {
                if (index === newFilterItemIndex) {
                    return {
                        ...filterItem,
                        operator: operator || filterItem.operator,
                        value: value || filterItem.value,
                    };
                }
                return filterItem;
            })
        );
        handleClose();
    };


    const open = Boolean(anchorEl);
    const booleanOpen = Boolean(booleanAnchorEl);
    const id = open || booleanOpen ? "simple-popover" : undefined;

    const renderFilterInput = (filterItem: FilterItem, index: number) => {
        const component = filterConditionComponents[filterItem.type][filterItem.operator].component;
        switch (component?.name) {
            case "AutocompleteCreateOption":
                return React.createElement(
                    component,
                    {
                        onValueSelected: (value: string) => handleOperatorChanged(index, filterItem.operator, value),
                        className: "autocomplete-filter",
                        inputClassName: "autocomplete-filter-input",
                    }
                );
            case "BooleanSelect":
                return React.createElement(
                    component,
                    {
                        className: "autocomplete-filter-input",
                        setSelectedOption: (value: string) => handleOperatorChanged(index, filterItem.operator, value),
                        ...(filterItem.value && {selectedOption: filterItem.value}),
                    }
                );
            default:
                <></>;
        }
    }

    // Update list of available filters based on API response and search value
    useEffect(() => {
        const items = Object.values(assetColumns).map(
            (column: DataAssetColumn): FilterItem => ({
                "id": column["name"],
                "icon": "match_case",
                "title": column["name"],
                "type": column["type"] as FilterType,
                "operator": defaultFilterOperator,
            })
        );
        if (searchValue) {
            setFilterItems(items.filter(item => item["title"].toLowerCase().includes(searchValue.toLowerCase())));
        } else {
            setFilterItems(items);
        }
    }, [assetColumns, searchValue]);

    // Pass added filters to the parent component to save them
    useEffect(() => {
        if (addedFilters?.length) {
            // TODO Olha: save only those filters that have all required fields set by a user
            const filters = addedFilters.map((filter) => ({
                "name": filter.title,
                "operator": filter.operator as string,
                "value": filter.value
            }));
            onFiltersUpdated(filters as MetricType["filters"]);
        }
    }, [addedFilters]);

    return (
        <Box className="flex-box-col-start" sx={{marginBottom: "32px"}}>
            <Typography
                variant="subtitle2"
                sx={{color: theme.palette.customColor.dark, fontWeight: 600, margin: "10px 0 12px 0"}}
            >
                Filters
            </Typography>

            <Box sx={{width: "100%", maxHeight: "250px", overflowY: "auto"}}>
                {addedFilters.map((filterItem: FilterItem, filterItemIndex) => (
                    <Box
                        key={filterItem.id}
                        className="measures-selected"
                        sx={{
                            position: "relative",
                            marginBottom: "12px",
                            "&:last-child": {
                                marginBottom: 0,
                            }
                        }}
                    >
                        <Box className="flex-box-align-center" sx={{marginBottom: "6px"}}>
                            <span
                                className="material-symbols-outlined"
                                style={{
                                    fontSize: "20px",
                                    color: theme.palette.customColor.purple,
                                    marginRight: "6px",
                                }}
                            >
                                {filterItem.icon}
                            </span>
                            <Typography
                                variant="subtitle2"
                                sx={{color: theme.palette.customColor.dark}}
                            >
                                {filterItem.title}
                            </Typography>
                        </Box>
                        <Box className="flex-box-align-center" sx={{marginLeft: "18px", padding: "6px"}}>
                            {/*Render Button depending on filterItemType */}
                            <ButtonComponent
                                variant="text"
                                label={filterConditionComponents[filterItem.type][filterItem.operator].title}
                                onClick={(e) => handleOpenBooleanPopover(e as unknown as React.MouseEvent<HTMLDivElement>, filterItem.type)}
                                sx={{
                                    minWidth: "auto",
                                    whiteSpace: "nowrap",
                                    marginRight: "10px",
                                    padding: "6px",
                                }}
                            />
                            <Popover
                                className="filter-popover-boolean"
                                id={id}
                                open={booleanOpen}
                                anchorEl={booleanAnchorEl}
                                onClose={handleClose}
                                anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "left"
                                }}
                            >
                                <Box sx={{marginTop: "8px"}}>
                                    {Object.keys(filterConditionComponents[filterItem.type]).map((operator: string) => (
                                        <Box
                                            key={`${filterItemIndex}-${filterItem.type}-${operator}`}
                                            className={`flex-box-center-space-between filter-text-boolean ${filterItem.operator === operator ? "active" : ""}`}
                                            sx={{cursor: "pointer", padding: "10px 12px"}}
                                            onClick={() => handleOperatorChanged(filterItemIndex, operator)}
                                        >
                                            <Typography
                                                variant="subtitle2"
                                                sx={{color: theme.palette.customColor.darkGrey}}
                                            >
                                                {operator}
                                            </Typography>
                                            {filterItem.operator === operator && (
                                                <span className="material-symbols-outlined">check</span>
                                            )}
                                        </Box>
                                    ))}
                                </Box>
                            </Popover>
                            {renderFilterInput(filterItem, filterItemIndex)}
                        </Box>
                        <IconButton
                            onClick={() => handleRemoveItem(filterItemIndex)}
                            sx={{
                                position: "absolute",
                                top: "4px",
                                right: "4px",
                                color: theme.palette.customColor.darkGrey,
                                "&:hover": {
                                    color: theme.palette.customColor.purple,
                                },
                            }}
                        >
                            <span className="material-symbols-outlined" style={{fontSize: "18px"}}>
                                delete
                            </span>
                        </IconButton>
                    </Box>
                ))}
            </Box>

            <ButtonComponent
                aria-describedby={id}
                onClick={handleOpenFilter}
                variant="textPurple"
                label="+ Add filter"
                sx={{fontSize: "16px", fontWeight: 600, margin: "12px 0 10px 0", padding: 0}}
            />
            <Popover
                className="filter-popover"
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left"
                }}
            >
                <SearchComponent
                    searchValue={searchValue}
                    setSearchValue={setSearchValue}/>

                <Box sx={{maxHeight: "340px", overflowY: "auto", marginTop: "8px"}}>
                    {filterItems.map((item: FilterItem) => (
                        <Box
                            key={item.id}
                            className="flex-box-align-center"
                            sx={{cursor: "pointer", padding: "10px 16px"}}
                            onClick={() => handleAddFilter(item)}
                        >
                            <span className="material-symbols-outlined">{item.icon}</span>
                            <Typography
                                variant="subtitle2"
                                sx={{color: theme.palette.customColor.darkGrey, marginLeft: "8px"}}
                            >
                                {item.title}
                            </Typography>
                        </Box>
                    ))}
                </Box>
            </Popover>
        </Box>
    );
};

export default AddFeaturesFilter;
