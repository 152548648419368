import React, {createContext, useContext, useState, ReactNode, SetStateAction, Dispatch, useEffect} from "react";

interface ContextValue {
    selectedGitBranch: string | null;
    setSelectedGitBranch: Dispatch<SetStateAction<string | null>>;
    isEditMode: boolean;
    toggleEditMode: Dispatch<SetStateAction<boolean>>;
    drawerOpen: boolean;
    setDrawerOpen: Dispatch<SetStateAction<boolean>>;
    isShowFeature: boolean;
    setIsShowFeature: Dispatch<SetStateAction<boolean>>;
}

const Context = createContext<ContextValue | undefined>(undefined);

const GlobalContextProvider: React.FC<{ children: ReactNode }> = ({children}) => {
    const [selectedGitBranch, setSelectedGitBranch] = useState<string | null>(() => {
        const storedBranch = localStorage.getItem("selectedGitBranch");
        return storedBranch ? JSON.parse(storedBranch) : null;
    });

    const [isEditMode, setIsEditMode] = useState<boolean>(() => {
        const storedIsEditMode = localStorage.getItem("isEditMode");
        return storedIsEditMode ? JSON.parse(storedIsEditMode) : false;
    });

    const [drawerOpen, setDrawerOpen] = useState<boolean>(() => {
        const storedDrawerOpen = localStorage.getItem("drawerOpen");
        return storedDrawerOpen ? JSON.parse(storedDrawerOpen) : false;
    });

    // TODO Olha: remove isShowFeature
    const [isShowFeature, setIsShowFeature] = useState<boolean>(() => {
        const storedisShowFeature = localStorage.getItem("isShowFeature");
        return storedisShowFeature ? JSON.parse(storedisShowFeature) : false;
    });

    useEffect(() => {
        localStorage.setItem("selectedGitBranch", JSON.stringify(selectedGitBranch));
    }, [selectedGitBranch]);

    useEffect(() => {
        localStorage.setItem("isEditMode", JSON.stringify(isEditMode));
    }, [isEditMode]);

    useEffect(() => {
        localStorage.setItem("drawerOpen", JSON.stringify(drawerOpen));
    }, [drawerOpen]);

    useEffect(() => {
        localStorage.setItem("isShowFeature", JSON.stringify(isShowFeature));
    }, [isShowFeature]);

    return (
        <Context.Provider value={{
            selectedGitBranch,
            setSelectedGitBranch,
            isEditMode,
            toggleEditMode: setIsEditMode,
            drawerOpen,
            setDrawerOpen,
            isShowFeature,
            setIsShowFeature
        }}>
            {children}
        </Context.Provider>
    );
};

const useGlobalContext = (): ContextValue => {
    const context = useContext(Context);
    if (context === undefined) {
        throw new Error("useGlobalContext must be used within a GlobalContextProvider");
    }
    return context;
};

export {GlobalContextProvider, useGlobalContext};
