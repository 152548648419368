import React from "react";
import {Box} from "@mui/material";
import AddFeaturesMeasures from "./add-features-measures/add-features-measures";
import AddFeaturesFilter from "./add-features-filter/add-features-filter";
import AddFeaturesFirstLastConfiguration
    from "./add-features-first-last-configuration/add-features-first-last-configuration";
import {DataAssetFullResponse} from "../../../../../services/data-assets";
import {FeatureModelType, MetricType} from "../../../feature-types";
import {getDataAssetName} from "../../../data-asset-name";

interface AddFeaturesModalContentProps {
    selectedAsset: DataAssetFullResponse;
    metricDraft?: MetricType;
    onMetricUpdated?: (metric: MetricType) => void;
    type: FeatureModelType;
}

const AddFeaturesModalContent: React.FC<AddFeaturesModalContentProps> = ({
                                                                             selectedAsset,
                                                                             metricDraft,
                                                                             type,
                                                                             onMetricUpdated
                                                                         }) => {
    const handleFiltersUpdated = (filters: MetricType["filters"]) => {
        const newMetric: MetricType = {
            ...metricDraft as MetricType,
            dataAssetId: getDataAssetName(selectedAsset),
            filters: filters
        };
        if (type === "metric" && onMetricUpdated) {
            onMetricUpdated(newMetric);
        }
    };

    const handleMeasuresUpdated = (measures: MetricType["measures"]) => {
        const newMetric: MetricType = {
            dataAssetId: getDataAssetName(selectedAsset),
            filters: metricDraft?.filters,
            measures: measures
        }
        console.log("New metric:")
        console.log(newMetric.dataAssetId)
        console.log(newMetric.filters)
        console.log(newMetric.measures)
        if (type === "metric" && onMetricUpdated) {
            onMetricUpdated(newMetric);
        }
    };

    const handleFirstLastUpdated = () => {
        // TODO Olha: implement
    };

    return (
        <Box sx={{margin: "32px 0"}}>
            <AddFeaturesFilter
                assetColumns={selectedAsset["columns"]}
                selectedFilters={metricDraft?.filters}
                onFiltersUpdated={handleFiltersUpdated}
            />
            {type === "metric" && (
                <AddFeaturesMeasures
                    selectedAsset={selectedAsset}
                    measures={metricDraft?.measures}
                    onMeasuresUpdated={handleMeasuresUpdated}
                />
            )}
            {type === "first/last" && <AddFeaturesFirstLastConfiguration selectedAsset={selectedAsset}
                                                                         onUpdate={handleFirstLastUpdated}/>}
        </Box>
    );
};

export default AddFeaturesModalContent;
