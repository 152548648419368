import React from 'react';
import {Breadcrumbs, Stack, Typography} from '@mui/material';
import {Link} from 'react-router-dom';
import theme from 'theme';
import {EntityDetailResponse} from 'services/entities';

interface Props {
    entityName: EntityDetailResponse["name"];
}

const BusinessEntitiesListBreadcrumbs: React.FC<Props> = ({entityName}) => {
    const breadcrumbs = [
        <Link to="/entities" key="1">
            <Typography variant="subtitle2"
                        sx={{color: theme.palette.customColor.grey, "&:hover": {textDecoration: "underline"}}}>
                Business entities
            </Typography>
        </Link>,
        <Typography key="2"
                    variant="subtitle2"
                    sx={{color: theme.palette.customColor.dark, textTransform: "capitalize"}}>
            {entityName}
        </Typography>,
    ];

    return (
        <Stack>
            <Breadcrumbs
                separator={<span className="material-symbols-outlined">chevron_right</span>}
                aria-label="breadcrumb"
                sx={{ '& .material-symbols-outlined': {fontSize: '16px'} }}
            >
                {breadcrumbs}
            </Breadcrumbs>
        </Stack>
    );
};

export default BusinessEntitiesListBreadcrumbs;
