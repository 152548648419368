import React from "react";
import ReactDOM from "react-dom/client";
import "index.scss";
import reportWebVitals from "reportWebVitals";
import {ThemeProvider} from "@mui/material";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import theme from "theme";
import "fonts.css";
import Onboarding from "page/onboarding/onboarding";
import BusinessEntities from "page/business-entities/business-entities";
import BusinessEntity from "page/business-entity/business-entity";
import {GlobalContextProvider} from "./context/global-context";
import DataAssets from "./page/data-assets/data-assets";
import DataAsset from "./page/data-asset/data-asset";
import Layout from "./page/layout/layout";


const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);

root.render(
    <React.StrictMode>
        <ThemeProvider theme={theme}>
            <GlobalContextProvider>
                <Router>
                    <Routes>
                        <Route path="/onboarding" element={<Onboarding/>}/>
                        <Route element={<Layout/>}>
                            <Route path="/entities" element={<BusinessEntities/>}/>
                            <Route path="/entities/:entityId" element={<BusinessEntity/>}/>
                            <Route path="/data-assets" element={<DataAssets/>}/>
                            <Route path="/data-assets/:assetId" element={<DataAsset/>}/>
                        </Route>
                    </Routes>
                </Router>
            </GlobalContextProvider>
        </ThemeProvider>
    </React.StrictMode>
);

reportWebVitals();
