import React, { useState } from 'react';
import { TextField, InputAdornment, IconButton } from '@mui/material';
import {VisibilityOffOutlined, VisibilityOutlined} from '@mui/icons-material';
import { TextFieldProps } from '@mui/material/TextField';

interface PasswordFieldProps extends Omit<TextFieldProps, 'type'> {
    name: string;
    placeholder: string;
    label?: string;
    required?: boolean;
    error?: boolean;
    helperText?: string;
    register?: any;
}

const PasswordField: React.FC<PasswordFieldProps> = ({
  name,
  label,
  placeholder,
  required,
  error,
  helperText,
  register,
  ...rest
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <TextField
      type={showPassword ? "text" : "password"}
      error={error}
      helperText={helperText}
      placeholder={placeholder}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end" className="input-password">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              edge="end"
            >
              {showPassword ? <VisibilityOffOutlined /> : <VisibilityOutlined />}
            </IconButton>
          </InputAdornment>
        )
      }}
      {...(register && register(name, {required: required}))}
      {...rest}
    />
  );
};

export default PasswordField;
