import React from 'react';
import {Box} from '@mui/material';
import AddEditBusinessEntitiesRelatedDatasetsTableFirst
    from "./add-edit-business-entities-related-datasets-table-first/add-edit-business-entities-related-datasets-table-first.component";
import AddEditBusinessEntitiesRelatedDatasetsTableSecond
    from "./add-edit-business-entities-related-datasets-table-second/add-edit-business-entities-related-datasets-table-second.component";


const AddEditBusinessEntitiesRelatedDatasets = () => {
    return (
        <Box className="flex-box-col-center" sx={{maxWidth: '1005px', width: '100%'}}>
            <AddEditBusinessEntitiesRelatedDatasetsTableFirst />

            <AddEditBusinessEntitiesRelatedDatasetsTableSecond />
        </Box>
    )
};

export default AddEditBusinessEntitiesRelatedDatasets;
